import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WidgetState {
    widgetId?: string;
    visible: boolean;
}

const defaultState: WidgetState = {
    widgetId: undefined,
    visible: false,
};

export const { reducer: widgetReducer, actions: widgetActions } = createSlice({
    name: 'widget',
    initialState: defaultState,
    reducers: {
        setWidgetId: (state: WidgetState, action: PayloadAction<string>) => {
            return { ...state, widgetId: action.payload };
        },
        setVisibleWidgets: (state: WidgetState, action: PayloadAction<string[]>) => {
            const visible = action.payload.includes(state.widgetId!!);
            return visible !== state.visible ? { ...state, visible } : state;
        },
    },
});
