import { BoundingBox, EventRenderRoute, EventSetBoundingBox, EventWidgetLoaded, Marker, RouteSegment } from './events';

const widgetLoaded = (): EventWidgetLoaded => ({
    type: 'EVENT_WIDGET_LOADED',
});

const setBoundingBox = (payload: { bbox: BoundingBox }): EventSetBoundingBox => ({
    type: 'EVENT_SET_BOUNDING_BOX',
    payload,
});

const renderRoute = (payload: { segments: RouteSegment[]; markers: Marker[] }): EventRenderRoute => ({
    type: 'EVENT_RENDER_ROUTE',
    payload,
});

export const eventFactory = {
    widgetLoaded,
    setBoundingBox,
    renderRoute,
};
