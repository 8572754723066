import React, { Suspense } from 'react';
import { batch, connect } from 'react-redux';

import { isGeoBooked } from '../../application/redux/data/dataSelectors';
import { fetchPois } from '../fetchData/fetchPois';
import { useEffectOnce } from './hooks';
import { fetchGeofences } from '../fetchData/fetchGeofences';
import { UpsellingContainer } from '../upselling/Upselling';
import SearchEditorContainer from '../search/SearchEditor';
import RoutesResultContainer from '../routes/RoutesResult';
import Options from '../options/Options';
import { RootState } from '../../configuration/setup/store';

export interface RoutePlannerProps {
    hasGeoBeenBooked?: boolean;
    fetchData: any;
}

export const RoutePlanner = React.memo((props: RoutePlannerProps) => {
    const { hasGeoBeenBooked, fetchData } = props;

    useEffectOnce(() => {
        fetchData();
    });

    return (
        <div className={'RoutePlanner height-100vh overflow-hidden display-flex flex-column'}>
            <Suspense fallback={''}>
                <div className={'overflow-auto padding-left-15 padding-top-15 padding-bottom-25 padding-right-15'}>
                    {hasGeoBeenBooked ? (
                        <React.Fragment>
                            <SearchEditorContainer />
                            <Options />
                        </React.Fragment>
                    ) : (
                        <div>
                            <UpsellingContainer />
                        </div>
                    )}
                </div>
                {hasGeoBeenBooked && <RoutesResultContainer />}
            </Suspense>
        </div>
    );
});

export const mapDispatchToProps = (dispatch: Function) => ({
    fetchData: () =>
        batch(() => {
            dispatch(fetchPois);
            dispatch(fetchGeofences);
        }),
});

export const mapStateToProps = (state: RootState) => ({
    hasGeoBeenBooked: isGeoBooked(state),
});

export const RoutePlannerContainer = connect(mapStateToProps, mapDispatchToProps)(RoutePlanner);
