const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
        MAP_SERVICE: string | undefined;
        ROUTEPLANNING_SERVICE: string | undefined;
        POI_SERVICE: string | undefined;
        GEOFENCE_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    sentryToken: string;
}

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        MAP_SERVICE: import.meta.env.VITE_MAP_SERVICE,
        ROUTEPLANNING_SERVICE: import.meta.env.VITE_ROUTEPLANNING_SERVICE,
        POI_SERVICE: import.meta.env.VITE_POI_SERVICE,
        GEOFENCE_SERVICE: import.meta.env.VITE_GEOFENCE_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '20e23989-4cc3-40d5-a622-d86f92e33896',
        oauthScope: ['openid', 'profile', 'email', 'map.read', 'location.read', 'geofence.read', 'here-map.proxy.read'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://ca07ef20a2bb4a6884241563083c0c1e@sentry.io/3061875'
};
