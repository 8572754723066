import { HEREVehicleAttibuteNames, VEHCILE_ATTRIBUTES_UNITS, VehicleAttributes } from '../../../application/redux/options/optionsReducer';
import { debouncedGaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { LabeledNumberInput } from '../../common/displaycomponents/LabeledNumberInput';

export const VehicleDimensionsInputs = (props: VehicleDimensionsInputsProps) => {
    const { totalLength, height, width, onVehicleAttributesUpdate = () => {} } = props;

    const handleTotalLengthChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.LENGTH]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Total Length Input Changed',
            label: `Changed Total Length Input::${value}`,
        });
    };

    const handleHeightChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.HEIGHT]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Height Input Changed',
            label: `Changed Height Input::${value}`,
        });
    };

    const handleWidthChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.WIDTH]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Width Input Changed',
            label: `Changed Width Input::${value}`,
        });
    };

    return (
        <div className={'display-flex align-items-end margin-bottom-10'}>
            <LabeledNumberInput
                className={'flex-1-1-0 padding-right-5'}
                labelId={'intl-msg:routeplanning.label.totalLength'}
                value={totalLength}
                unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.LENGTH]}
                onChange={handleTotalLengthChange}
            />
            <LabeledNumberInput
                className={'flex-1-1-0 padding-right-5 padding-left-5'}
                labelId={'intl-msg:routeplanning.label.height'}
                value={height}
                unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.HEIGHT]}
                onChange={handleHeightChange}
            />
            <LabeledNumberInput
                className={'flex-1-1-0 padding-left-5'}
                labelId={'intl-msg:routeplanning.label.width'}
                value={width}
                unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.WIDTH]}
                onChange={handleWidthChange}
            />
        </div>
    );
};

interface VehicleDimensionsInputsProps {
    totalLength?: number;
    height?: number;
    width?: number;
    onVehicleAttributesUpdate?: (attributes: Partial<VehicleAttributes>) => void;
}
