import without from 'lodash/fp/without';
import flow from 'lodash/fp/flow';
import last from 'lodash/fp/last';
import add from 'lodash/fp/add';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import reverse from 'lodash/fp/reverse';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Address {
    title: string;
    position: Coordinates;
    type: string;
    resultType: string;
}

export interface Coordinates {
    lat: number;
    lng: number;
}

export type Waypoint = {
    id: number;
    address?: string;
    coordinates?: Coordinates;
};

export interface SearchState {
    waypoints: Waypoint[];
    waypointsOrder: number[];
    suggestedAddresses: { [key: number]: Address[] };
}
export interface FetchSuggestedAddressesActionPayload {
    id: number;
    addresses: Address[];
}

const defaultState : SearchState = {
    waypoints: [
        {
            id: 100,
            address: '',
        },
        {
            id: 200,
            address: '',
        },
    ],
    waypointsOrder: [100, 200],
    suggestedAddresses: {},
};

const waypointChanged = (state: SearchState, action: PayloadAction<Waypoint>) => {
    const waypointToUpdate = state.waypoints.find(waypoint => waypoint.id === action.payload.id);

    if (!waypointToUpdate) {
        return state;
    }
    return { ...state, waypoints: [...without([waypointToUpdate], state.waypoints), action.payload] };
};

const prependWith = (name?: string) => (address?: string) => {
    if (name === undefined) {
        return address;
    }

    if (address === undefined) {
        return name;
    }

    return `${name} (${address})`;
};

const prependNameToAddress = (payload: any) => {
    const { address, name, coordinates } = payload;
    const newAddress = prependWith(name)(address);
    return {
        coordinates,
        address: newAddress,
    } as any;
};

const setStartPoint = (state: SearchState, action: PayloadAction<any>): SearchState => {
    const waypointId = state.waypointsOrder[0];
    const waypointToUpdate = state.waypoints.find(waypoint => waypoint.id === waypointId);

    if (!waypointToUpdate) {
        return state;
    }
    return {
        ...state,
        waypoints: [
            ...without([waypointToUpdate], state.waypoints),
            { id: waypointId, ...prependNameToAddress(action.payload) },
        ],
    };
};

const setEndPoint = (state: SearchState, action: PayloadAction<any>) => {
    const waypointId = last(state.waypointsOrder);
    const waypointToUpdate = state.waypoints.find(waypoint => waypoint.id === waypointId);

    if (!waypointToUpdate) {
        return state;
    }
    return {
        ...state,
        waypoints: [
            ...without([waypointToUpdate], state.waypoints),
            { id: waypointId, ...prependNameToAddress(action.payload) },
        ],
    };
};

const suggestedAddressesChanged = (state: SearchState, action: PayloadAction<FetchSuggestedAddressesActionPayload>) => {
    const id = action.payload.id;
    return {
        ...state,
        suggestedAddresses: {
            ...state.suggestedAddresses,
            [id]: action.payload.addresses,
        },
    };
};

const insertAt = (array: number[], index: number, item: any) => {
    const newArray = [...array];
    newArray.splice(index, 0, item);
    return newArray;
};

const getHighestId = flow(sortBy(['id']), last, get('id'), add(1));

const waypointAdded = (state: SearchState, action: PayloadAction<number>) => {
    const { waypoints, waypointsOrder } = state;

    const newId = getHighestId(waypoints);
    const newWaypoint = {
        id: newId,
        address: '',
    };

    const position = action.payload !== -1 ? waypointsOrder.indexOf(action.payload) : waypointsOrder.length;

    const newPosition = position + 1;
    const newOrder = insertAt(waypointsOrder, newPosition, newId);

    return { ...state, waypoints: [...waypoints, newWaypoint], waypointsOrder: newOrder };
};

const waypointRemoved = (state: SearchState, action: PayloadAction<number>) => {
    const { waypoints, waypointsOrder } = state;
    if (waypoints.length <= 2) {
        return state;
    }
    const updatedWaypoints = waypoints.filter((waypoint: Waypoint) => waypoint.id !== action.payload);
    const updatedOrder = waypointsOrder.filter((waypointId: number) => waypointId !== action.payload);
    return { ...state, waypoints: updatedWaypoints, waypointsOrder: updatedOrder };
};

const waypointsOrderInverted = (state: SearchState) => {
    const updatedOrder = reverse([...state.waypointsOrder]);
    return { ...state, waypointsOrder: updatedOrder };
};

const waypointsOrderChanged = (state: SearchState, action: PayloadAction<Waypoint[]>) => {
    const updatedWaypoints = action.payload;
    const updatedOrder = updatedWaypoints.map(waypoint => waypoint.id);
    return { ...state, waypoints: updatedWaypoints, waypointsOrder: updatedOrder };
};

export const { reducer : searchReducer, actions: searchActions } = createSlice({
    name: 'search',
    initialState: defaultState,
    reducers: {
        waypointChanged: (state, action: PayloadAction<Waypoint>) => {
            return waypointChanged(state, action);
        },
        suggestedAddressesChanged: (state, action: PayloadAction<FetchSuggestedAddressesActionPayload>) => {
            return suggestedAddressesChanged(state, action);
        },
        fetchSuggestedAddressesFailed: () => {
            // do nothing
        },
        waypointAdded: (state, action: PayloadAction<number>) => {
            return waypointAdded(state, action);
        },
        waypointRemoved: (state, action: PayloadAction<number>) => {
            return waypointRemoved(state, action);
        },
        waypointsOrderInverted: (state) => {
            return waypointsOrderInverted(state);
        },
        waypointsOrderChanged: (state, action: PayloadAction<Waypoint[]>) => {
            return waypointsOrderChanged(state, action);
        },
        setRoutingStartPoint: (state, action: PayloadAction<any>) => {
            return setStartPoint(state, action);
        },
        setRoutingEndPoint: (state, action: PayloadAction<any>) => {
            return setEndPoint(state, action);
        },
    }
});
