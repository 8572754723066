import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

interface ErrorMessageProps {
    error?: Error;
}

const gaPushError = (label: string) => {
    gaPush({
        category: TRACKING_CATEGORIES.ROUTE_CHOOSER,
        action: 'Error Displayed',
        label,
    });
};

const NoRouteError = () => {
    useEffect(() => {
        gaPushError('Displayed Error::No Route For Given Vehicle Options');
    });

    return (
        <div className={'alert alert-warning'}>
            <FormattedMessage id={'intl-msg:routeplanning.error.noRouteForGivenVehicleOptions'} />
        </div>
    );
};

const UnexpectedError = () => {
    useEffect(() => {
        gaPushError('Displayed Error::Unexpected Error');
    });

    return (
        <div className={'alert alert-warning'}>
            <FormattedMessage id={'intl-msg:routeplanning.error.unexpectedError'} />
        </div>
    );
};

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
    if (!error) {
        return null;
    }

    if (error.message.includes('400')) {
        return <NoRouteError />;
    }

    return <UnexpectedError />;
};
