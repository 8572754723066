import Fuse from 'fuse.js';

import { Address } from '../../application/redux/search/searchReducer';
import { RootState } from '../../configuration/setup/store';
import { getPois } from '../../application/redux/data/dataSelectors';

const mapAddress = (poi: any): Address => ({
    title: `${poi.name ?? ''} ${poi.address ?? ''}`.trim(),
    type: poi.type.toLowerCase(),
    resultType: poi.type.toLowerCase(),
    position: { lat: poi.coordinates.latitude, lng: poi.coordinates.longitude },
});

export const searchPoiAddresses = (getState: () => RootState, value: string) => {
    const pois = getPois(getState());
    const options = {
        keys: ['name', 'address'],
        threshold: 0.2,
        distance: 30,
    };
    const fuse = new Fuse(pois, options);
    const results = fuse.search(value);
    return results.map(result => mapAddress(result.item)) as Address[];
};
