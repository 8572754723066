import React from 'react';

import { EmissionClass, HazardClass } from './VehicleAttributePresets';
import { HEREVehicleAttibuteNames, VehicleAttributes } from '../../../application/redux/options/optionsReducer';
import { debouncedGaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { LabeledSelect } from '../../common/displaycomponents/LabeledSelect';
import { FormattedMessage } from 'react-intl';

const emissionClassOptionLabels = [
    { id: EmissionClass.NONE, label: <FormattedMessage id={'intl-msg:routeplanning.label.none'} /> },
    { id: EmissionClass.EURO_I, label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroI'} /> },
    {
        id: EmissionClass.EURO_II,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroII'} />,
    },
    {
        id: EmissionClass.EURO_III,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroIII'} />,
    },
    {
        id: EmissionClass.EURO_IV,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroIV'} />,
    },
    { id: EmissionClass.EURO_V, label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroV'} /> },
    {
        id: EmissionClass.EURO_VI,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroVI'} />,
    },
    {
        id: EmissionClass.EURO_EEV,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.euroEEV'} />,
    },
    {
        id: EmissionClass.ELECTRIC_VEHICLE,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.emissionClass.electricVehicle'} />,
    },
];

const hazardClassOptionLabels = [
    { id: HazardClass.NONE, label: <FormattedMessage id={'intl-msg:routeplanning.label.none'} /> },
    {
        id: HazardClass.EXPLOSIVE,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.explosive'} />,
    },
    { id: HazardClass.GAS, label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.gas'} /> },
    {
        id: HazardClass.FLAMMABLE,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.flammable'} />,
    },
    {
        id: HazardClass.COMBUSTIBLE,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.combustible'} />,
    },
    {
        id: HazardClass.ORGANIC,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.organic'} />,
    },
    {
        id: HazardClass.POISON,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.poison'} />,
    },
    {
        id: HazardClass.RADIOACTIVE,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.radioactive'} />,
    },
    {
        id: HazardClass.CORROSIVE,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.corrosive'} />,
    },
    {
        id: HazardClass.INHALATION_HAZARD,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.inhalationHazard'} />,
    },
    {
        id: HazardClass.HARMFUL_TO_WATER,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.harmfulToWater'} />,
    },
    {
        id: HazardClass.MISCELLANEOUS,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification.miscellaneous'} />,
    },
];

export const EmissionAndHazardSelects = (props: EmissionAndHazardSelectsProps) => {
    const { emissionClass, hazardClass, onVehicleAttributesUpdate = () => {} } = props;

    const handleEmissionClassChange = (selectedOption: string) => {
        const emissionType = selectedOption as EmissionClass;
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.EMISSION_TYPE]: emissionType });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Emission Class Input Changed',
            label: `Changed Emission Class Input::${selectedOption}`,
        });
    };

    const handleHazardClassificationChange = (selectedOption: string) => {
        const shippedHazardousGoods = selectedOption as HazardClass;
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.SHIPPED_HAZARDOUS_GOODS]: shippedHazardousGoods });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Hazard Classification Input Changed',
            label: `Changed Hazard Classification Input::${selectedOption}`,
        });
    };

    return (
        <React.Fragment>
            <div className={'display-flex align-items-end margin-bottom-10'}>
                <LabeledSelect
                    className={'width-100pct'}
                    label={<FormattedMessage id={'intl-msg:routeplanning.label.emissionClass'} />}
                    optionLabels={emissionClassOptionLabels}
                    selectedOption={emissionClass ?? EmissionClass.NONE}
                    onChange={handleEmissionClassChange}
                    dropup={true}
                />
            </div>
            <div className={'display-flex align-items-end margin-bottom-10'}>
                <LabeledSelect
                    className={'width-100pct'}
                    label={<FormattedMessage id={'intl-msg:routeplanning.label.hazardClassification'} />}
                    optionLabels={hazardClassOptionLabels}
                    selectedOption={hazardClass ?? HazardClass.NONE}
                    onChange={handleHazardClassificationChange}
                    dropup={true}
                />
            </div>
        </React.Fragment>
    );
};

interface EmissionAndHazardSelectsProps {
    emissionClass?: EmissionClass;
    hazardClass?: HazardClass;
    onVehicleAttributesUpdate?: (attributes: Partial<VehicleAttributes>) => void;
}
