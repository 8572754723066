import { combineReducers } from 'redux';

import { configReducer, langReducer, loginReducer } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer';
import { dataReducer } from './application/redux/data/dataReducer';
import { searchReducer } from './application/redux/search/searchReducer';
import { routesReducer } from './application/redux/routes/routesReducer';
import { widgetReducer } from './application/redux/widget/widgetReducer';
import { optionsReducer } from './application/redux/options/optionsReducer';

export const rootReducer = () =>
    combineReducers({
        data: dataReducer,
        search: searchReducer,
        routes: routesReducer,
        options: optionsReducer,
        widget: widgetReducer,
        config: configReducer,
        lang: langReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer,
    });
