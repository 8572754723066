import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';
import { connect, ConnectedProps } from 'react-redux';
import { SortableElement } from 'react-sortable-hoc';

import { WaypointInput } from './WaypointInput';
import { AddWaypointButton } from './AddWaypointButton';
import { getSuggestedAddresses } from '../../application/redux/search/searchSelectors';
import { searchActions, Waypoint } from '../../application/redux/search/searchReducer';
import { AppDispatch, RootState } from '../../configuration/setup/store';
import { fetchAddresses } from '../fetchData/fetchAddresses';
import { routesActions } from '../../application/redux/routes/routesReducer';
import { clearRoute } from '../livemonitorCommunication/propagateRoutesToParent';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

interface Suggestion {
    label: string;
    position: Coordinates;
}

interface Coordinates {
    lat: number;
    lng: number;
}

const getIcon = (index: number, max: number) => {
    if (index === 0) {
        return 'start';
    } else if (index === max) {
        return 'finish';
    }
    return 'arrow-down';
};

export const WaypointListItem = SortableElement<WaypointListItemProps>((props: WaypointListItemProps) => {
    const {
        deleteSuggestedRoutes,
        loadAddresses,
        suggestedAddresses,
        waypointAdded,
        waypointChanged,
        waypointRemoved,
        value,
        orderIndex,
        allowRemove,
        totalWaypoints,
    } = props;

    const waypoint = value;
    const waypointId = waypoint.id;
    const addresses = getOr([], waypointId, suggestedAddresses);

    const handleValueChanged = (id: number, address: string) => {
        waypointChanged({
            id,
            address,
        } as Waypoint);
        loadAddresses(id, address);
    };

    const handleInputCleared = () => deleteSuggestedRoutes();

    const handleSuggestionSelected = (id: number, suggestion: Suggestion) => {
        deleteSuggestedRoutes();
        waypointChanged({
            id,
            address: suggestion.label,
            coordinates: { ...suggestion.position },
        });
    };

    const handleWaypointAdd = () => {
        waypointAdded(waypointId);
        gaPush({
            category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
            action: 'Add Waypoint Clicked',
            label: 'Clicked Add Waypoint',
        });
    };
    const handleWaypointRemove = () => {
        waypointRemoved(waypointId);
        gaPush({
            category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
            action: 'Remove Waypoint Clicked',
            label: 'Clicked Remove Waypoint',
        });
    };

    return (
        <div className={'bg-white padding-top-5 margin-top--5'}>
            <WaypointInput
                waypoint={waypoint}
                icon={getIcon(orderIndex, totalWaypoints)}
                suggestedAddresses={addresses}
                onInputValueChanged={handleValueChanged}
                onSuggestionSelected={handleSuggestionSelected}
                onInputCleared={handleInputCleared}
                onRemove={handleWaypointRemove}
                allowRemove={allowRemove}
            />
            <AddWaypointButton onClick={handleWaypointAdd} />
        </div>
    );
});

WaypointListItem.defaultProps = {
    waypointChanged: () => noop,
    waypointAdded: () => noop,
    waypointRemoved: () => noop,
    loadAddresses: () => noop,
    deleteSuggestedRoutes: () => noop,
};

type PassedComponentProps = {
    value: Waypoint;
    orderIndex: number;
    allowRemove: boolean;
    totalWaypoints: number;
};
type WaypointListItemProps = ConnectedProps<typeof connector> & PassedComponentProps;

export const mapStateToProps = (state: RootState) => ({
    suggestedAddresses: getSuggestedAddresses(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    deleteSuggestedRoutes: () => {
        dispatch(routesActions.suggestedRoutesRemoved());
        clearRoute();
    },
    loadAddresses: (id: number, address: string) => {
        dispatch(fetchAddresses(id, address));
    },
    waypointAdded: (siblingWaypointId: number) => {
        dispatch(searchActions.waypointAdded(siblingWaypointId));
    },
    waypointChanged: (waypoint: Waypoint) => {
        dispatch(searchActions.waypointChanged(waypoint));
    },
    waypointRemoved: (waypointId: number) => {
        dispatch(searchActions.waypointRemoved(waypointId));
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const WaypointListItemContainer = connector(WaypointListItem);
