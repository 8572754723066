import classNames from 'classnames';

interface AddWaypointButtonProps {
    onClick: () => void;
}

export const AddWaypointButton = ({ onClick }: AddWaypointButtonProps) => {
    const wrapperClassNames = classNames(
        'position-relative',
        'padding-1',
        'cursor-pointer',
        'text-color-dark',
        'hover-text-color-darkest',
        'opacity-0',
        'hover-opacity-100'
    );

    const iconWrapperClassNames = classNames(
        'bg-white',
        'padding-left-5',
        'padding-right-5',
        // 'padding-top-1',
        // 'padding-bottom-2',
        'text-size-10',
        'position-absolute',
        'top-50pct',
        'left-50pct',
        'transform translate-centered'
    );

    return (
        <div className={wrapperClassNames} onClick={onClick} data-testid={'addWaypointButton'}>
            <hr className={'margin-0 border-width-2'} />
            <span className={iconWrapperClassNames}>
                <span className={'rioglyph rioglyph-plus'} />
            </span>
        </div>
    );
};
