/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

const release = SERVICE_VERSION;
const environment = SERVICE_ENVIRONMENT;

const isProduction = environment === 'production';

// should have been called before using it here
// ideally before even rendering your react app
Sentry.init({
    dsn: isProduction ? config.sentryToken : null,
    environment,
    release,
});

export const reportErrorToSentry = (...args) => {
    Sentry.captureException(...args);
};
