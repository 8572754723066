import isEmpty from 'lodash/fp/isEmpty';

import {
    HEREVehicleAttibuteNames,
    VEHCILE_ATTRIBUTES_UNITS,
    VehicleAttributes,
} from '../../application/redux/options/optionsReducer';
import { WaypointWithCoordinates } from './fetchWaypointCoordinates';
import { TollVehicleType } from '../options/vehicle/VehicleAttributePresets';

const pathOfHereApi = '/v8/match/routelinks';

interface RouteParameters {
    departureTime: string;
    departureDate: string;
    waypoints: WaypointWithCoordinates[];
    vehicleAttributes: VehicleAttributes;
    currency: string;
    vehicleCostsPerKilometer: number;
    considerTraffic?: boolean;
    avoidMotorway?: boolean;
    avoidToll?: boolean;
    calculateAlternatives: boolean;
    ignoreRestrictionsAroundWaypoints?: boolean;
}

const createModeOptionsUrl = (parameters: RouteParameters) => {
    let parameterString = '';
    parameterString += '&mode=fastest';
    parameterString += `;${
        parameters.vehicleAttributes[HEREVehicleAttibuteNames.TOLL_VEHICLE_TYPE] === TollVehicleType.CAR
            ? 'car'
            : 'truck'
    }`;
    parameterString += parameters.considerTraffic ? ';traffic:enabled' : ';traffic:disabled';
    parameterString += parameters.avoidMotorway ? ';motorway:-2' : '';
    parameterString += parameters.avoidToll ? ';tollroad:-2' : '';
    parameterString += parameters.ignoreRestrictionsAroundWaypoints
        ? '&ignoreWaypointVehicleRestriction=1250;0.5;all'
        : '';
    return parameterString;
};

const createWaypointsParameterUrl = (waypoints: WaypointWithCoordinates[]) => {
    let parameterString = '';
    waypoints.forEach((waypoint, index) => {
        parameterString += `&waypoint${index}=${waypoint.coordinates.lat},${waypoint.coordinates.lng}`;
    });
    return parameterString;
};

const createVehicleAttributesUrl = (vehicleAttributes: VehicleAttributes) => {
    let parameterString = '';
    for (const key in vehicleAttributes) {
        if (vehicleAttributes.hasOwnProperty(key)) {
            const unit = !isEmpty(VEHCILE_ATTRIBUTES_UNITS[key]) ? VEHCILE_ATTRIBUTES_UNITS[key] : '';
            parameterString += `&${key}=${vehicleAttributes[key]}${unit}`;
        }
    }
    if (
        !isEmpty(vehicleAttributes) &&
        vehicleAttributes.trailerNumberAxles &&
        vehicleAttributes.trailerNumberAxles > 0
    ) {
        parameterString += '&trailersCount=1';
    }

    return parameterString;
};

const createVehicleCostsParameterUrl = (currency: string, vehicleCostsPerKilometer: number): string => {
    return `&currency=${currency}&vehicle_cost=${vehicleCostsPerKilometer}`;
};

export const createRouteQuery = (parameters: RouteParameters, apiKey: string) => {
    const modeOptionsUrl = createModeOptionsUrl(parameters);
    const waypointParameterUrl = createWaypointsParameterUrl(parameters.waypoints);
    const vehicleAttributesUrl = createVehicleAttributesUrl(parameters.vehicleAttributes);
    const vehicleCostParametersUrl = createVehicleCostsParameterUrl(
        parameters.currency,
        parameters.vehicleCostsPerKilometer
    );
    const numberOfAlternativesUrl =
        parameters.calculateAlternatives && parameters.waypoints && Object.keys(parameters.waypoints).length === 2
            ? '&alternatives=2'
            : '';

    let additionalParameters =
        '?' +
        '&traverseGates=true' +
        '&tollPass=Senior_Pass%2Ctransponder' +
        `&departure=${`${parameters.departureDate}T${parameters.departureTime}:00`.replace('+', '%2B')}`;

    const apiKeyParameter = `&apiKey=${apiKey}`;
    additionalParameters += modeOptionsUrl;
    additionalParameters += waypointParameterUrl;
    additionalParameters += vehicleAttributesUrl;
    additionalParameters += numberOfAlternativesUrl;
    additionalParameters += vehicleCostParametersUrl;
    additionalParameters += apiKeyParameter;

    return pathOfHereApi + decodeURIComponent(additionalParameters);
};
