import React, { FunctionComponent } from 'react';
import noop from 'lodash/fp/noop';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import { searchActions } from '../../application/redux/search/searchReducer';
import { AppDispatch, RootState } from '../../configuration/setup/store';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

export const WaypointsOptions: FunctionComponent<WaypointsOptionsProps> = React.memo(props => {
    const { waypointsOrderInverted, waypointAdded } = props;

    const handleInvertOrderButton = () => {
        waypointsOrderInverted();
        gaPush({
            category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
            action: 'Invert Waypoint Order Clicked',
            label: 'Clicked Invert Waypoint Order',
        });
    };

    const handleAddDestination = () => {
        waypointAdded();
        gaPush({
            category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
            action: 'Add Destination Clicked',
            label: 'Add Destination Button',
        });
    };

    return (
        <div className={'InvertWaypoints display-flex align-items-center justify-content-between'}>
            <button
                className={'btn btn-sm btn-link btn-link-inline margin-left-0 text-color-dark'}
                onClick={handleAddDestination}
            >
                <span className={'rioglyph rioglyph-plus-sign text-color-dark margin-right-5'}></span>
                <FormattedMessage id={'intl-msg:routeplanning.addDestination'} />
            </button>
            <button className={'btn btn-muted btn-icon-only'} type={'button'} onClick={handleInvertOrderButton}>
                <span className={'rioglyph rioglyph-sort'} />
            </button>
        </div>
    );
});

WaypointsOptions.defaultProps = {
    waypointsOrderInverted: () => noop,
    waypointAdded: () => noop,
};

type WaypointsOptionsProps = ConnectedProps<typeof connector>;

export const mapStateToProps = (state: RootState) => ({});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    waypointsOrderInverted: () => {
        dispatch(searchActions.waypointsOrderInverted());
    },
    waypointAdded: () => {
        dispatch(searchActions.waypointAdded(-1));
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const WaypointsOptionsContainer = connector(WaypointsOptions);
