import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import { createSelector } from 'reselect';

import { Waypoint } from './searchReducer';
import { RootState } from '../../../configuration/setup/store';

const baseSelector = (state: RootState) => state.search;

const getWaypoints = flow(baseSelector, subState => subState.waypoints);
const getWaypointsOrder = flow(baseSelector, subState => subState.waypointsOrder);

export const getOrderedWaypoints = createSelector(
    getWaypoints,
    getWaypointsOrder,
    (waypoints: Waypoint[], order: number[]) =>
        order
            .map(waypointId => waypoints.find(point => point.id === waypointId))
            .filter(id => id !== undefined) as Waypoint[]
);

export const areWaypointsValid = createSelector(
    getWaypoints,
    (waypoints: Waypoint[]) => !waypoints.some(waypoint => isEmpty(waypoint.address))
);

export const getSuggestedAddresses = flow(baseSelector, subState => subState.suggestedAddresses);
