import { fetchData } from '../../configuration/setup/fetch';
import { config } from '../../config';
import { getAccessToken } from '../../configuration';
import { dataActions } from '../../application/redux/data/dataReducer';
import { mapPoi, PoiDto } from './mappers/mapPoi';

export const fetchPois = async (dispatch: Function, getState: Function) => {
    try {
        const response = await fetchData(config.backend.POI_SERVICE, getAccessToken(getState()));
        const pois = response.items || [];
        const poisDto = pois.map((poi: PoiDto) => mapPoi(poi));

        dispatch(dataActions.fetchPoisSuccess(poisDto));
    } catch (error) {
        dispatch(dataActions.fetchPoisFailed());
    }
};
