import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

interface CalculateRouteButtonProps {
    isDisabled?: boolean;
    isLoading?: boolean;
    onClick: () => void;
}

export const CalculateRouteButton = (props: CalculateRouteButtonProps) => {
    const { isDisabled = false, isLoading = false, onClick } = props;

    const wrapperClassNames = classNames(
        'display-flex justify-content-end align-items-start',
        isDisabled && 'cursor-not-allowed'
    );
    const calculateButtonClassNames = classNames(
        'btn btn-primary',
        isDisabled && 'disabled',
        isLoading && 'btn-loading'
    );

    return (
        <div className={wrapperClassNames}>
            <button className={calculateButtonClassNames} onClick={onClick} data-testid={'CalculateButton'}>
                <span className={'rioglyph rioglyph-route'}></span>
                <FormattedMessage id={'intl-msg:routeplanning.label.calculate'} />
            </button>
        </div>
    );
};
