import compact from 'lodash/fp/compact';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';
import reduce from 'lodash/fp/reduce';

import {
    subMilliseconds,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    addDays,
    addHours,
    addMinutes,
} from 'date-fns';

interface DateTimePart {
    name: string;
    unit: string;
    add: any;
    difference: any;
}

const dateTimeParts = [
    {
        name: 'days',
        unit: 'd',
        add: addDays,
        difference: differenceInDays,
    },
    {
        name: 'hours',
        unit: 'h',
        add: addHours,
        difference: differenceInHours,
    },
    {
        name: 'minutes',
        unit: 'min',
        add: addMinutes,
        difference: differenceInMinutes,
    },
];

const formatDurationInParts = (durationInParts: any) => {
    if (durationInParts.days >= 7) {
        return `> ${durationInParts.days} d`;
    }
    const parts = compact(
        reduce(
            (result: any, dateTimePart: DateTimePart) => {
                if (result.length < 2) {
                    const value = durationInParts[dateTimePart.name];
                    if (value || result.length > 0) {
                        return [...result, value ? `${value} ${dateTimePart.unit}` : null];
                    }
                }
                return result;
            },
            [],
            dateTimeParts
        )
    );
    return isEmpty(parts) ? '0 min' : join(' ', parts);
};

const toDurationInParts = (durationInMs: number) => {
    const now = Date.now();
    let date = subMilliseconds(now, durationInMs);
    return reduce(
        (durationInParts: any, dateTimePart: DateTimePart) => {
            const amount = Math.abs(Math.floor(dateTimePart.difference(date, now)));
            date = dateTimePart.add(date, amount);
            return {
                ...durationInParts,
                [dateTimePart.name]: amount,
            };
        },
        {},
        dateTimeParts
    );
};

export const formatDuration = (durationInSec: number) => {
    const durationInParts = toDurationInParts(durationInSec * 1000);
    return formatDurationInParts(durationInParts);
};

export const formatDistance = (distance: number, fractionDigits?: number) => {
    return `${(distance / 1000).toFixed(fractionDigits)} km`;
};
