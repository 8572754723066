import { FormattedMessage } from 'react-intl';

import { DepartureContainer } from './departure/Departure';
import { TourOptionsContainer } from './tour/TourOptions';
import { VehicleOptionsContainer } from './vehicle/VehicleOptions';
import { CostOptionsContainer } from './costs/CostOptions.container';

export const Options = () => {
    return (
        <div className={'RouteOptions margin-top-15 margin-bottom-15'}>
            <div className={'text-size-18 text-medium'}>
                <FormattedMessage id={'intl-msg:routeplanning.label.tourAndOptions'} />
            </div>
            <DepartureContainer />
            <TourOptionsContainer />
            <VehicleOptionsContainer />
            <CostOptionsContainer />
        </div>
    );
};

export default Options;
