import first from 'lodash/fp/first';
import isEmpty from 'lodash/fp/isEmpty';
import last from 'lodash/fp/last';

import { getSelectedRoute, getSuggestedRoutes } from '../../application/redux/routes/routesSelectors';
import { Coordinates } from '../../application/redux/search/searchReducer';
import { Route } from '../fetchData/mappers/mapRoutes';
import { WaypointWithCoordinates } from '../fetchData/fetchWaypointCoordinates';
import { sendMessage } from './MessageHandler';
import { AppDispatch, RootState } from '../../configuration/setup/store';
import { formatDistance, formatDuration } from '../routes/formatUtils';
import { eventFactory } from './eventFactory';

interface MarkerProps {
    active: boolean;
    iconNames: string[];
    markerColor: string;
    name?: string;
}

interface Marker {
    id: string;
    position: Coordinates;
    markerProps: MarkerProps;
}

interface Segment {
    points: Coordinates[];
    alternative: boolean;
}

const createWaypointMarker = (waypoint: WaypointWithCoordinates, icon: string): Marker => ({
    id: waypoint.id.toString(),
    position: waypoint.coordinates,
    markerProps: {
        active: false,
        iconNames: [icon],
        markerColor: 'bg-map-marker-route',
    },
});

const createRouteInfoMarker = (coordinates: Coordinates, isActive: boolean, info: string): Marker => {
    return {
        id: (coordinates.lat + coordinates.lng).toString(),
        position: coordinates,
        markerProps: {
            active: isActive,
            iconNames: ['route'],
            markerColor: 'bg-map-marker-route',
            name: info,
        },
    };
};

const getRouteInfoMarkerPosition = (coordinates: Coordinates[]): Coordinates => {
    return coordinates[Math.floor(coordinates.length / 2)];
};

const createRouteInfo = (route: Route): string => {
    const { travelTime, distance } = route.summary;
    const formattedTravelTime = formatDuration(travelTime);
    const formattedDistance = formatDistance(distance);

    return `${formattedDistance} / ${formattedTravelTime}`;
};

const createMarkersArray = (routes: Route[], selectedRoute: number): Marker[] => {
    const waypoints = routes[0].waypoints;

    if (isEmpty(waypoints)) {
        return [];
    }

    const firstMarker = createWaypointMarker(first(waypoints)!, 'start');
    const lastMarker = createWaypointMarker(last(waypoints)!, 'finish');

    const intermediaryWaypoints = waypoints.slice(1, waypoints.length - 1);
    const intermediaryMarkers = intermediaryWaypoints.map(waypoint => createWaypointMarker(waypoint, 'arrow-down'));

    const routeInfoMarkers = createRouteInfoMarker(
        getRouteInfoMarkerPosition(routes[selectedRoute].routeCoordinates),
        true,
        createRouteInfo(routes[selectedRoute])
    );

    return [firstMarker, lastMarker, ...intermediaryMarkers, routeInfoMarkers];
};

// ensure selected route is rendered atop alternatives in UiKit
const putSelectedRouteLast = (segments: Segment[], from: number): Segment[] => {
    const modifiableCopy = [...segments];
    const selectedRoute = modifiableCopy.splice(from, 1);
    return [...modifiableCopy, ...selectedRoute];
};

const createSegmentsArray = (routes: Route[], selectedRoute: number): Segment[] => {
    const segments = routes.map((route, index) => ({
        points: route.routeCoordinates,
        alternative: selectedRoute !== index,
    }));

    return putSelectedRouteLast(segments, selectedRoute);
};

const requestBoundingBox = (routes: Route[]) => {
    const waypointWithCoordinates: Coordinates[] = routes[0].waypoints.map(waypoint => waypoint.coordinates);
    const routeCoordinates: Coordinates[] = routes.flatMap(route => route.routeCoordinates);
    const coordinates = [...waypointWithCoordinates, ...routeCoordinates];

    const latitudes = coordinates.map(coordinate => coordinate.lat);
    const longitudes = coordinates.map(coordinate => coordinate.lng);

    sendMessage(
        eventFactory.setBoundingBox({
            bbox: {
                topLeft: { lat: Math.max(...latitudes), lng: Math.min(...longitudes) },
                bottomRight: { lat: Math.min(...latitudes), lng: Math.max(...longitudes) },
            },
        })
    );
};

export const propagateRoute = (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const suggestedRoutes = getSuggestedRoutes(state);
    const selectedRoute = getSelectedRoute(state);

    if (!suggestedRoutes || isEmpty(suggestedRoutes)) {
        return;
    }

    const route = {
        segments: createSegmentsArray(suggestedRoutes, selectedRoute),
        markers: createMarkersArray(suggestedRoutes, selectedRoute),
    };

    sendMessage(eventFactory.renderRoute(route));
};

export const clearRoute = () => {
    const route = {
        segments: [],
        markers: [],
    };

    sendMessage(eventFactory.renderRoute(route));
};

export const propagateBoundingBox = (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const suggestedRoutes = getSuggestedRoutes(state);

    if (!suggestedRoutes || isEmpty(suggestedRoutes)) {
        return;
    }

    requestBoundingBox(suggestedRoutes);
};
