import { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/fp/isEmpty';

import { AppDispatch, RootState } from '../../configuration/setup/store';
import { fetchRoutes } from '../fetchData/fetchRoutes';
import {
    getFetchRoutesError,
    getSelectedRoute,
    getSuggestedRoutes,
    isFetchRoutesInProgress,
} from '../../application/redux/routes/routesSelectors';
import { RouteSummaryItem } from './RouteSummaryItem';
import { RouteResultSeparator } from './RouteResultSeparator';
import { routesActions } from '../../application/redux/routes/routesReducer';
import { CalculateRouteButton } from './CalculateRouteButton';
import { areWaypointsValid } from '../../application/redux/search/searchSelectors';
import { clearRoute, propagateBoundingBox, propagateRoute } from '../livemonitorCommunication/propagateRoutesToParent';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';
import { ErrorMessage } from './RouteError';

export const RoutesResult: FunctionComponent<RoutesResultProps> = props => {
    const {
        allWaypointsValid,
        fetchRoutesInProgress,
        fetchRoutesError,
        selectedRoute,
        suggestedRoutes,
        startFetchingRoutes,
        onSelectedRouteChanged,
        repropagateRoute,
    } = props;

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        !isEmpty(suggestedRoutes) && repropagateRoute();
    }, [selectedRoute, suggestedRoutes, repropagateRoute]);

    const handleCalculateRouteButtonClick = () => {
        startFetchingRoutes();
        gaPush({
            category: TRACKING_CATEGORIES.ROUTE_CHOOSER,
            action: 'Calculate Routes Clicked',
            label: 'Clicked Calculate Routes',
        });
    };

    const handleRouteItemClick = (index: number) => {
        onSelectedRouteChanged(index);
        gaPush({
            category: TRACKING_CATEGORIES.ROUTE_CHOOSER,
            action: 'Suggested Route Clicked',
            label: 'Clicked Suggested Route',
        });
    };

    return (
        <div className={'RoutesResult padding-15 flex-1-0 position-relative'} data-testid={'RoutesResult'}>
            <RouteResultSeparator />
            <CalculateRouteButton
                isDisabled={!allWaypointsValid}
                isLoading={fetchRoutesInProgress}
                onClick={handleCalculateRouteButtonClick}
            />
            <div className={'list-group margin-top-15'}>
                <ErrorMessage error={fetchRoutesError} />
                {suggestedRoutes &&
                    suggestedRoutes.map((route, index) => (
                        <RouteSummaryItem
                            key={`${route.summary.distance}-${route.summary.travelTime}`}
                            route={route}
                            selected={selectedRoute === index}
                            onRouteItemClick={() => handleRouteItemClick(index)}
                        />
                    ))}
            </div>
        </div>
    );
};

RoutesResult.defaultProps = {
    allWaypointsValid: undefined,
    fetchRoutesInProgress: false,
    selectedRoute: 0,
    suggestedRoutes: [],
    startFetchingRoutes: () => noop,
    onSelectedRouteChanged: () => noop,
    repropagateRoute: () => noop,
};

type RoutesResultProps = ConnectedProps<typeof connector>;

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startFetchingRoutes: () => {
        dispatch(fetchRoutes);
    },
    onSelectedRouteChanged: (selectedRoute: number) => {
        dispatch(routesActions.selectedRouteChanged(selectedRoute));
    },
    repropagateRoute: () => {
        clearRoute();
        dispatch(propagateRoute);
        dispatch(propagateBoundingBox);
    },
});

export const mapStateToProps = (state: RootState) => ({
    allWaypointsValid: areWaypointsValid(state),
    fetchRoutesInProgress: isFetchRoutesInProgress(state),
    fetchRoutesError: getFetchRoutesError(state),
    selectedRoute: getSelectedRoute(state),
    suggestedRoutes: getSuggestedRoutes(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const RoutesResultContainer = connector(RoutesResult);
export default RoutesResultContainer;
