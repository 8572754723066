import { Coordinates, searchActions, Waypoint } from '../../application/redux/search/searchReducer';
import { fetchAddress } from './fetchAddresses';
import { getHereSettings } from '../../application/redux/data/dataSelectors';
import { getLocale } from '../../configuration';
import { getOrderedWaypoints } from '../../application/redux/search/searchSelectors';

export interface WaypointWithCoordinates {
    id: number;
    address?: string;
    coordinates: Coordinates;
}

export const enrichWaypointsWithCoordinates = async (
    dispatch: Function,
    getState: Function
): Promise<WaypointWithCoordinates[]> => {
    const apikey = getHereSettings(getState())?.apikey ?? '';
    const locale = getLocale(getState());
    const orderedWaypoints = getOrderedWaypoints(getState());
    const orderedWaypointsWithCoordinates = await Promise.all(
        orderedWaypoints.map(waypoint => enrichWaypointWithCoordinates(waypoint, locale, apikey))
    );

    const changedWaypointsWithCoordinates = orderedWaypointsWithCoordinates.filter(
        (value, index) => value !== orderedWaypoints[index]
    );
    changedWaypointsWithCoordinates.forEach(value => dispatch(searchActions.waypointChanged(value)));

    return orderedWaypointsWithCoordinates;
};

const enrichWaypointWithCoordinates = async (
    waypoint: Waypoint,
    locale: string,
    apikey: string
): Promise<WaypointWithCoordinates> => {
    if (waypoint.coordinates === undefined && waypoint.address) {
        const address = await fetchCoordinates(waypoint.address, locale, apikey);
        return {
            id: waypoint.id,
            address: `${address.title}`,
            coordinates: {
                lat: address.position.lat,
                lng: address.position.lng,
            },
        };
    }
    return {
        id: waypoint.id,
        address: waypoint.address,
        coordinates: waypoint.coordinates!!,
    };
};

const fetchCoordinates = async (address: string, locale: string, apikey: string) => {
    const currentMapCenter = {
        lat: 48.13642,
        lng: 11.57755,
    };
    const addresses = await fetchAddress(address, currentMapCenter, locale, apikey);
    return addresses[0];
};
