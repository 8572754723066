import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import { FormattedMessage } from 'react-intl';

import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../configuration';
import { Moment } from 'moment/moment';

interface DepartureDateProps {
    value: Date;
    onChange: (date: Date) => void;
}

export const DepartureDate = ({ value, onChange }: DepartureDateProps) => {
    const locale = useSelector(getLocale);

    const handleDateChange = (selectedDate: Moment | string, isValid: boolean) => {
        if (!isValid || typeof selectedDate === 'string') {
            return;
        }
        onChange(selectedDate.toDate());
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Date Input Changed',
            label: 'Changed Date Input',
        });
    };

    return (
        <div className={'flex-1-1-0 margin-right-5'}>
            <label className={'control-label'}>
                <FormattedMessage id={'intl-msg:routeplanning.label.date'} />
            </label>
            <DatePicker
                className={'min-width-auto'}
                closeOnSelect={true}
                value={value}
                locale={locale}
                onChange={handleDateChange}
                dateFormat={true}
                timeFormat={false}
                dropup={true}
            />
        </div>
    );
};
