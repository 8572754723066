import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';

import { AppDispatch, RootState } from '../../../configuration/setup/store';
import {
    getAvoidMotorway,
    getAvoidToll,
    getCalculateAlternatives,
    getConsiderTraffic,
    getIgnoreRestrictionsAroundWaypoints,
} from '../../../application/redux/options/optionsSelectors';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { TourOption } from './TourOption';
import { routesActions } from '../../../application/redux/routes/routesReducer';
import { optionsActions } from '../../../application/redux/options/optionsReducer';

const TourOptions = (props: TourOptionsProps) => {
    const {
        avoidMotorway,
        avoidToll,
        considerTraffic,
        ignoreRestrictionsAroundWaypoints,
        calculateAlternatives,
        onAvoidMotorwayToggle,
        onAvoidTollToggle,
        onConsiderTrafficToggle,
        onIgnoreRestrictionsAroundWaypointsToggle,
        onCalculateAlternativesToggle,
        intl,
    } = props;

    const handleExpanderPanelToggle = (value: boolean) => {
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Tour Options Expander Toggled',
            label: `Toggled Tour Options Expander::${value ? 'Open' : 'Closed'}`,
        });
    };

    const handleConsiderTrafficToggle = (value: boolean) => {
        onConsiderTrafficToggle(value);
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Consider Traffic Toggled',
            label: `Toggled Consider Traffic::${value ? 'On' : 'Off'}`,
        });
    };

    const handleAvoidMotorwayToggle = (value: boolean) => {
        onAvoidMotorwayToggle(value);
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Avoid Motorways Toggled',
            label: `Toggled Avoid Motorways::${value ? 'On' : 'Off'}`,
        });
    };

    const handleAvoidTollToggle = (value: boolean) => {
        onAvoidTollToggle(value);
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Avoid Toll Toggled',
            label: `Toggled Avoid Toll::${value ? 'On' : 'Off'}`,
        });
    };

    const handleIgnoreRestrictionsAroundWaypointsToggle = (value: boolean) => {
        onIgnoreRestrictionsAroundWaypointsToggle(value);
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Ignore Restrictions Around Waypoints Toggled',
            label: `Toggled Ignore Restrictions Around Waypoints::${value ? 'On' : 'Off'}`,
        });
    };

    const handleCalculateAlternativesToggle = (value: boolean) => {
        onCalculateAlternativesToggle(value);
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Calculate Alternatives Toggled',
            label: `Toggled Calculate Alternatives::${value ? 'On' : 'Off'}`,
        });
    };

    return (
        <ExpanderPanel
            className={'TourOptions margin-bottom-0'}
            title={<FormattedMessage id={'intl-msg:routeplanning.label.tourOptions'} />}
            bodyClassName={'margin-bottom-10'}
            iconLeft
            bsStyle={'separator'}
            onToggle={handleExpanderPanelToggle}
        >
            <TourOption
                intlId={'intl-msg:routeplanning.label.considerTrafficConditions'}
                checked={considerTraffic}
                onToggle={handleConsiderTrafficToggle}
            />
            <TourOption
                intlId={'intl-msg:routeplanning.label.avoidMotorways'}
                checked={avoidMotorway}
                onToggle={handleAvoidMotorwayToggle}
            />
            <TourOption
                intlId={'intl-msg:routeplanning.label.avoidToll'}
                checked={avoidToll}
                onToggle={handleAvoidTollToggle}
            />
            <TourOption
                intlId={'intl-msg:routeplanning.label.ignoreRestrictionsAroundWaypoints'}
                checked={ignoreRestrictionsAroundWaypoints}
                onToggle={handleIgnoreRestrictionsAroundWaypointsToggle}
                tooltipContent={intl.formatMessage({
                    id: 'intl-msg:routeplanning.tooltip.ignoreRestrictionsAroundWaypoints',
                })}
            />
            <TourOption
                intlId={'intl-msg:routeplanning.label.calculateAlternatives'}
                checked={calculateAlternatives}
                onToggle={handleCalculateAlternativesToggle}
                tooltipContent={intl.formatMessage({
                    id: 'intl-msg:routeplanning.tooltip.calculateAlternatives',
                })}
            />
        </ExpanderPanel>
    );
};

type TourOptionsProps = ConnectedProps<typeof connector> & WrappedComponentProps;

export const mapStateToProps = (state: RootState) => ({
    avoidMotorway: getAvoidMotorway(state),
    avoidToll: getAvoidToll(state),
    considerTraffic: getConsiderTraffic(state),
    ignoreRestrictionsAroundWaypoints: getIgnoreRestrictionsAroundWaypoints(state),
    calculateAlternatives: getCalculateAlternatives(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onAvoidMotorwayToggle: (value: boolean) => {
        dispatch(optionsActions.avoidMotorwayToggled(value));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
    onAvoidTollToggle: (value: boolean) => {
        dispatch(optionsActions.avoidTollToggled(value));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
    onConsiderTrafficToggle: (value: boolean) => {
        dispatch(optionsActions.considerTrafficToggled(value));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
    onIgnoreRestrictionsAroundWaypointsToggle: (value: boolean) => {
        dispatch(optionsActions.ignoreRestrictionsAroundWaypointsToggled(value));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
    onCalculateAlternativesToggle: (value: boolean) => {
        dispatch(optionsActions.calculateAlternativesToggled(value));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const TourOptionsContainer = connector(injectIntl(TourOptions));
