import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { format, fromUnixTime } from 'date-fns';

import { AppDispatch, RootState } from '../../../configuration/setup/store';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { getDepartureDate, getDepartureTime } from '../../../application/redux/options/optionsSelectors';
import { DepartureDate } from './DepartureDate';
import { DepartureTime } from './DepartureTime';
import { routesActions } from '../../../application/redux/routes/routesReducer';
import { optionsActions } from '../../../application/redux/options/optionsReducer';

const Departure = (props: DepartureProps) => {
    const { departureTime, departureDate, onDepartureTimeChange, onDepartureDateChange } = props;

    const handleExpanderPanelToggle = (value: boolean) => {
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Departure Expander Toggled',
            label: `Toggled Departure Expander::${value ? 'Open' : 'Closed'}`,
        });
    };

    const handleUseCurrentDateTime = () => {
        onDepartureDateChange(fromUnixTime(Date.now() / 1000));
        onDepartureTimeChange(format(fromUnixTime(Date.now() / 1000), 'HH:mm'));
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Use Current Date And Time Button Clicked',
            label: 'Clicked Use Current Date And Time Button',
        });
    };

    return (
        <ExpanderPanel
            className={'DepartureOptions margin-bottom-0'}
            title={<FormattedMessage id={'intl-msg:routeplanning.label.departure'} />}
            iconLeft
            bsStyle={'separator'}
            onToggle={handleExpanderPanelToggle}
        >
            <div className={'display-flex align-items-end'}>
                <DepartureDate value={departureDate} onChange={onDepartureDateChange} />
                <DepartureTime value={departureTime} onChange={onDepartureTimeChange} />
            </div>
            <button
                className={'btn btn-primary btn-link btn-link-inline text-size-12 margin-bottom-5'}
                onClick={handleUseCurrentDateTime}
            >
                <FormattedMessage id={'intl-msg:routeplanning.label.useCurrentDateTime'} />
            </button>
        </ExpanderPanel>
    );
};

type DepartureProps = ConnectedProps<typeof connector>;

export const mapStateToProps = (state: RootState) => ({
    departureTime: getDepartureTime(state),
    departureDate: getDepartureDate(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onDepartureTimeChange: (value: string) => {
        dispatch(optionsActions.departureTimeChanged(value));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
    onDepartureDateChange: (date: Date) => {
        dispatch(optionsActions.departureDateChanged(date));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const DepartureContainer = connector(Departure);
