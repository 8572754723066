import TimePicker from '@rio-cloud/rio-uikit/TimePicker';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/fp/debounce';

import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';

const debouncedGaPush = debounce(1000, ({ category, action, label }) => {
    gaPush({
        category,
        action,
        label,
    });
});

interface DepartureTimeProps {
    value: string;
    onChange: (value: string) => void;
}

export const DepartureTime = ({ value, onChange }: DepartureTimeProps) => {
    const handleTimeChange = (time: string) => {
        onChange(time);
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Time Input Changed',
            label: 'Changed Time Input',
        });
    };

    return (
        <div className={'flex-1-1-0 margin-left-5'}>
            <label className={'control-label'}>
                <FormattedMessage id={'intl-msg:routeplanning.label.time'} />
            </label>
            <TimePicker value={value} className={'form-group'} onChange={handleTimeChange} />
        </div>
    );
};
