import flow from 'lodash/fp/flow';

import { RootState } from '../../../configuration/setup/store';

const baseSelector = (state: RootState) => state.data;

export const getHereSettings = flow(baseSelector, subState => subState.hereSettings);

export const isGeoBooked = flow(baseSelector, subState => subState.isGeoBooked);

export const getPois = flow(baseSelector, subState => subState.pois);
export const isFetchPoisFailed = flow(baseSelector, subState => subState.fetchPoisFailed);

export const getGeofences = flow(baseSelector, subState => subState.geofences);
export const isFetchGeofencesFailed = flow(baseSelector, subState => subState.fetchGeofencesFailed);
