import { Route } from '../../../features/fetchData/mappers/mapRoutes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RoutesState {
    fetchRoutesInProgress: boolean;
    selectedRoute: number;
    suggestedRoutes?: Route[];
    fetchRoutesError?: Error;
}

const defaultState: RoutesState = {
    fetchRoutesInProgress: false,
    selectedRoute: 0,
};

const { reducer, actions } = createSlice({
    name: 'routes',
    initialState: defaultState,
    reducers: {
        fetchRoutesTriggered: (state: RoutesState) => ({
            ...state,
            fetchRoutesInProgress: true,
        }),
        selectedRouteChanged: (state: RoutesState, action: PayloadAction<number>) => ({
            ...state,
            selectedRoute: action.payload,
        }),
        suggestedRoutesChanged: (state: RoutesState, action: PayloadAction<Route[]>) => ({
            ...state,
            fetchRoutesInProgress: false,
            suggestedRoutes: action.payload,
        }),
        suggestedRoutesRemoved: (state: RoutesState) => ({
            ...defaultState,
        }),
        fetchRouteSuggestionsFailed: (state: RoutesState, action: PayloadAction<Error>) => ({
            ...state,
            fetchRoutesInProgress: false,
            fetchRoutesError: action.payload,
        }),
    },
});

export { reducer as routesReducer, actions as routesActions };
