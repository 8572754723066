import isNil from 'lodash/fp/isNil';

interface CustomerPoiDto {
    id: string;
    name: string;
    type: PoiType;
    coordinates: Coordinates;
    address?: string;
    category?: string;
}

type WorkshopAddressDto = {
    street?: string;
    zip?: string;
    city?: string;
    country: string;
};

interface WorkshopPoiDto {
    id: string;
    name?: string;
    type: PoiType;
    coordinates: Coordinates;
    address?: WorkshopAddressDto;
    contact?: {
        email?: string;
        phone?: string;
    };
}

export type PoiDto = CustomerPoiDto | WorkshopPoiDto;

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export enum PoiType {
    Workshop = 'WORKSHOP',
    Customer = 'CUSTOMER',
}

export interface Poi {
    id: string;
    name?: string;
    type: PoiType;
    coordinates: Coordinates;
    address?: string;

    // customer POI specific
    category?: string;

    // workshop POI specific
    contact?: {
        email?: string;
        phone?: string;
    };
}

export const mapPoi = (poi: PoiDto): Poi => {
    switch (poi.type) {
        case PoiType.Customer:
            return poi as CustomerPoiDto;
        case PoiType.Workshop:
            const workshopPoiDto = poi as WorkshopPoiDto;
            return {
                id: workshopPoiDto.id,
                name: workshopPoiDto.name,
                type: workshopPoiDto.type,
                coordinates: workshopPoiDto.coordinates,
                address: mapAddress(workshopPoiDto.address),
                contact: workshopPoiDto.contact,
            };
    }
};

const mapAddress = (address?: WorkshopAddressDto) => {
    if (isNil(address) || isNil(address.zip) || isNil(address.zip) || isNil(address.city)) {
        return undefined;
    }

    return `${address.street}, ${address.zip} ${address.city} ${address.country}`;
};
