import React, { ReactElement } from 'react';
import Select from '@rio-cloud/rio-uikit/Select';
import isNil from 'lodash/fp/isNil';
import { SelectOption } from '@rio-cloud/rio-uikit';

export const LabeledSelect = (props: LabeledSelectProps) => {
    const {
        className,
        label,
        optionLabels,
        selectedOption,
        toggleButtonLabel,
        onChange = () => {},
        dropup = false,
        dropdownClassName,
    } = props;

    const handleOptionChange = (option: SelectOption | undefined) => {
        if (option !== undefined) {
            onChange(option.id);
        }
    };

    const value = isNil(selectedOption) ? undefined : [selectedOption];

    return (
        <div className={className}>
            <label className={'control-label'}>{label}</label>
            <div className={'width-100pct'}>
                <Select
                    options={optionLabels}
                    onChange={handleOptionChange}
                    value={value}
                    selectedOptionText={toggleButtonLabel}
                    dropup={dropup}
                    dropdownClassName={dropdownClassName}
                />
            </div>
        </div>
    );
};

interface LabeledSelectProps {
    className?: string;
    label: ReactElement;
    optionLabels: SelectOption[];
    selectedOption?: string;
    toggleButtonLabel?: string | React.ReactNode;
    onChange?: (selectedOption: string) => void;
    dropup?: boolean;
    dropdownClassName?: string;
}
