import { fetchData } from '../../configuration/setup/fetch';
import { config } from '../../config';
import { getAccessToken } from '../../configuration';
import { dataActions } from '../../application/redux/data/dataReducer';

const findHereConfiguration = (configurations: any) => configurations.provider === 'here';

export const fetchHereSettings = async (dispatch: Function, getState: Function) => {
    try {
        const hereConfiguration = await fetchData(
            `${config.backend.MAP_SERVICE}/configurations`,
            getAccessToken(getState())
        );
        const hereSettings = { apikey: hereConfiguration.items.find(findHereConfiguration).api_key };
        dispatch(dataActions.hereSettingsChanged(hereSettings));
    } catch (error: any) {
        dispatch(dataActions.fetchHereSettingsFailed(error));
    }
};
