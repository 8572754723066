import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppDispatch, RootState } from '../../configuration/setup/store';
import { widgetActions } from '../../application/redux/widget/widgetReducer';
import { LivemonitorEvent } from './events';
import { resolveAddress } from '../app/resolveAddress';
import { Coordinates, searchActions } from '../../application/redux/search/searchReducer';
import { fetchPois } from '../fetchData/fetchPois';
import { fetchGeofences } from '../fetchData/fetchGeofences';

export const sendMessage = (message: any) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

const receiveMessage = (event: MessageEvent, dispatch: AppDispatch) => {
    const livemonitorEvent = event.data as LivemonitorEvent;

    switch (livemonitorEvent.type) {
        case 'EVENT_SET_WIDGET_ID':
            dispatch(widgetActions.setWidgetId(livemonitorEvent.payload));
            break;
        case 'EVENT_SET_VISIBLE_WIDGETS':
            dispatch(widgetActions.setVisibleWidgets(livemonitorEvent.payload));
            break;
        case 'EVENT_SET_ROUTING_START_POINT':
            dispatch(async (innerDispatch, getState: () => RootState) => {
                const point = { ...livemonitorEvent.payload };
                const enrichedRoutingPoint = await enrichRoutingPointWithAddress(point, getState);
                innerDispatch(searchActions.setRoutingStartPoint(enrichedRoutingPoint));
            });
            break;
        case 'EVENT_SET_ROUTING_END_POINT':
            dispatch(async (innerDispatch, getState: () => RootState) => {
                const point = { ...livemonitorEvent.payload };
                const enrichedRoutingPoint = await enrichRoutingPointWithAddress(point, getState);
                innerDispatch(searchActions.setRoutingEndPoint(enrichedRoutingPoint));
            });
            break;
        case 'EVENT_REFRESH_POIS':
            dispatch(fetchPois);
            break;
        case 'EVENT_REFRESH_GEOFENCES':
            dispatch(fetchGeofences);
            break;
    }
};

const enrichRoutingPointWithAddress = async (
    point: {
        coordinates: Coordinates;
        name?: string;
        address?: string;
    },
    getState: () => RootState
) => {
    let enrichedPoint = { ...point };
    if (!enrichedPoint.address) {
        const address = (await resolveAddress(point.coordinates, getState)) ?? undefined;
        enrichedPoint = {
            ...enrichedPoint,
            address,
        };
    }
    return enrichedPoint;
};

export const MessageHandler = React.memo(({ dispatch }: Props) => {
    useEffect(() => {
        const listenerCallback = (event: MessageEvent) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
});

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatch,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const MessageHandlerContainer = connector(MessageHandler);
export default MessageHandlerContainer;
