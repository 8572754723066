import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';
import { RootState } from '../../configuration/setup/store';
import { getWidgetVisibility } from '../../application/redux/widget/widgetSelectors';
import { WrappedComponentProps } from 'react-intl/lib';
import IframeResizer from 'iframe-resizer-react';

export const marketplaceUrlForGeo = 'https://upselling.marketplace.rio.cloud/?productId=rio-sku00000047&level=10';

const Upselling = (props: UpsellingProps & WrappedComponentProps) => {
    const { isWidgetVisible } = props;
    useEffect(() => {
        if (isWidgetVisible) {
            gaPush({
                category: TRACKING_CATEGORIES.UPSELLING,
                action: 'Geo Upselling Message Displayed',
                label: 'Displayed Geo Upselling Message',
            });
        }
    }, [isWidgetVisible]);

    return (
        <div className={'margin-bottom-20'}>
            <IframeResizer src={`${marketplaceUrlForGeo}&resourceId=''`} title={'UpsellingHintWidget'} height={0} />
        </div>
    );
};

type UpsellingProps = { isWidgetVisible: boolean };

const mapStateToProps = (state: RootState) => ({
    isWidgetVisible: getWidgetVisibility(state),
});

export const UpsellingContainer = connect(mapStateToProps, {})(injectIntl(Upselling));
