import classNames from 'classnames';

export const RouteResultSeparator = () => {
    const resultSeparatorClassNames = classNames(
        'position-absolute',
        'pointer-events-none',
        'left-0',
        'right-0',
        'top-0',
        'height-20',
        'overflow-shadow-bottom',
        'transform',
        'translate-top-100'
    );

    return <div className={resultSeparatorClassNames}></div>;
};
