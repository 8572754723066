/* eslint-disable react/style-prop-object */
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import classNames from 'classnames';

import { Route } from '../fetchData/mappers/mapRoutes';
import { formatDuration } from './formatUtils';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';
import { parseISO } from 'date-fns';

interface RouteItemProps {
    route: Route;
    selected: boolean;
    onRouteItemClick: () => void;
}

export const RouteSummaryItem = (props: RouteItemProps) => {
    const { route, selected, onRouteItemClick } = props;
    const { summary, costs } = route;

    const handleExpanderPanelToggle = (value: boolean) => {
        gaPush({
            category: TRACKING_CATEGORIES.COST_DETAILS,
            action: 'Tour Cost Details Expander Toggled',
            label: `Toggled Tour Cost Details Expander::${value ? 'Open' : 'Closed'}`,
        });
    };

    const formattedDuration = formatDuration(summary.travelTime);

    const listGroupItemClassNames = classNames(
        'list-group-item',
        'text-color-status-driving',
        selected ? 'bg-highlight-lightest' : 'bg-white',
        !selected && 'cursor-pointer'
    );

    return (
        <div className={listGroupItemClassNames} onClick={onRouteItemClick}>
            <div className={'display-flex align-items-center text-size-16 text-medium'}>
                <div className={'flex-1-0'}>
                    <span className={'RouteDuration margin-right-3'}>{formattedDuration}</span>
                    <span>{'('}</span>
                    <FormattedNumber value={summary.distance / 1000} style={'decimal'} maximumFractionDigits={2} />
                    <span>{' km)'}</span>
                </div>
                {!!costs.totalCost && (
                    <div className={'RouteCost white-space-nowrap'}>
                        <FormattedNumber
                            value={costs.totalCost}
                            style={'currency'}
                            currency={costs.currency}
                            maximumFractionDigits={2}
                        />
                    </div>
                )}
            </div>
            <div>
                <span className={'rioglyph rioglyph-finish margin-right-3'} />
                <span className={'RouteArrival'} data-testid={'RouteArrival'}>
                    <FormattedTime
                        value={parseISO(summary.arrival)}
                        year={'numeric'}
                        month={'long'}
                        day={'2-digit'}
                        weekday={'short'}
                    />
                </span>
            </div>
            {!!costs.totalCost && (
                <ExpanderPanel
                    className={'margin-bottom-0'}
                    title={<FormattedMessage id={'intl-msg:routeplanning.label.costDetails'} />}
                    headerClassName={'text-color-status-driving text-medium'}
                    iconLeft
                    bsStyle={'separator'}
                    onToggle={handleExpanderPanelToggle}
                >
                    <div className={'display-flex'}>
                        <FormattedMessage id={'intl-msg:routeplanning.label.costDetails.vehicle'} />
                        <span className={'flex-1-0'}>{': '}</span>
                        <FormattedNumber
                            value={costs.vehicleCost}
                            style={'currency'}
                            currency={costs.currency}
                            maximumFractionDigits={2}
                        />
                    </div>
                    <div className={'display-flex'}>
                        <FormattedMessage id={'intl-msg:routeplanning.label.costDetails.toll'} />
                        <span className={'flex-1-0'}>{': '}</span>
                        <FormattedNumber
                            value={costs.tollCost}
                            style={'currency'}
                            currency={costs.currency}
                            maximumFractionDigits={2}
                        />
                    </div>
                </ExpanderPanel>
            )}
        </div>
    );
};
