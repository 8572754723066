import { format, fromUnixTime } from 'date-fns';
import {
    EmissionClass,
    HazardClass,
    Profile,
    TollVehicleType,
    truckAndTrailer40tAttributes,
    vehicleAttributesProfiles,
} from '../../../features/options/vehicle/VehicleAttributePresets';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'options';

export enum HEREVehicleAttibuteNames {
    TOLL_VEHICLE_TYPE = 'tollVehicleType',
    HEIGHT = 'height',
    LENGTH = 'length',
    WIDTH = 'width',
    LIMITED_WEIGHT = 'limitedWeight',
    WEIGHT_PER_AXLE = 'weightPerAxle',
    VEHCILE_NUMBER_AXLES = 'vehicleNumberAxles',
    TRAILER_NUMBER_AXLES = 'trailerNumberAxles',
    EMISSION_TYPE = 'emissionType',
    SHIPPED_HAZARDOUS_GOODS = 'shippedHazardousGoods',
}

export interface VehicleAttributesUnit {
    readonly [propName: string]: string;
}

export const VEHCILE_ATTRIBUTES_UNITS: VehicleAttributesUnit = {
    [HEREVehicleAttibuteNames.HEIGHT]: 'm',
    [HEREVehicleAttibuteNames.LENGTH]: 'm',
    [HEREVehicleAttibuteNames.WIDTH]: 'm',
    [HEREVehicleAttibuteNames.LIMITED_WEIGHT]: 't',
    [HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]: 't',
};

export interface VehicleAttributes {
    [HEREVehicleAttibuteNames.TOLL_VEHICLE_TYPE]?: TollVehicleType;
    [HEREVehicleAttibuteNames.HEIGHT]?: number;
    [HEREVehicleAttibuteNames.LENGTH]?: number;
    [HEREVehicleAttibuteNames.WIDTH]?: number;
    [HEREVehicleAttibuteNames.LIMITED_WEIGHT]?: number;
    [HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]?: number;
    [HEREVehicleAttibuteNames.VEHCILE_NUMBER_AXLES]?: number;
    [HEREVehicleAttibuteNames.TRAILER_NUMBER_AXLES]?: number;
    [HEREVehicleAttibuteNames.EMISSION_TYPE]?: EmissionClass;
    [HEREVehicleAttibuteNames.SHIPPED_HAZARDOUS_GOODS]?: HazardClass;

    readonly [propName: string]: number | string | undefined;
}

export interface OptionsState {
    departureTime: string;
    departureDate: Date;
    considerTraffic: boolean;
    avoidMotorway: boolean;
    avoidToll: boolean;
    calculateAlternatives: boolean;
    ignoreRestrictionsAroundWaypoints: boolean;
    selectedProfile: Profile;
    hasCustomAttributes: boolean;
    vehicleAttributes: VehicleAttributes;
    vehicleCostsPerKilometer: number;
    currency: string;
}

export const initialState: OptionsState = {
    departureTime: format(fromUnixTime(Date.now() / 1000), 'HH:mm'),
    departureDate: fromUnixTime(Date.now() / 1000),
    considerTraffic: true,
    avoidMotorway: false,
    avoidToll: false,
    calculateAlternatives: false,
    ignoreRestrictionsAroundWaypoints: true,
    selectedProfile: Profile.TRUCK_AND_TRAILER_40T,
    hasCustomAttributes: false,
    vehicleAttributes: truckAndTrailer40tAttributes,
    vehicleCostsPerKilometer: 0,
    currency: 'EUR',
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        departureTimeChanged: (state: OptionsState, action: PayloadAction<string>): void => {
            state.departureTime = action.payload;
        },
        departureDateChanged: (state: OptionsState, action: PayloadAction<Date>): void => {
            state.departureDate = action.payload;
        },
        avoidMotorwayToggled: (state: OptionsState, action: PayloadAction<boolean>): void => {
            state.avoidMotorway = action.payload;
        },
        avoidTollToggled: (state: OptionsState, action: PayloadAction<boolean>): void => {
            state.avoidToll = action.payload;
        },
        considerTrafficToggled: (state: OptionsState, action: PayloadAction<boolean>): void => {
            state.considerTraffic = action.payload;
        },
        calculateAlternativesToggled: (state: OptionsState, action: PayloadAction<boolean>): void => {
            state.calculateAlternatives = action.payload;
        },
        ignoreRestrictionsAroundWaypointsToggled: (state: OptionsState, action: PayloadAction<boolean>): void => {
            state.ignoreRestrictionsAroundWaypoints = action.payload;
        },
        selectedProfileChanged: (state: OptionsState, action: PayloadAction<Profile>): void => {
            state.selectedProfile = action.payload;
            state.hasCustomAttributes = false;
            state.vehicleAttributes = vehicleAttributesProfiles[action.payload];
        },
        vehicleAttributesUpdated: (state: OptionsState, action: PayloadAction<Partial<VehicleAttributes>>): void => {
            state.hasCustomAttributes = true;
            state.vehicleAttributes = { ...state.vehicleAttributes, ...action.payload };
        },
        setVehicleCostsPerKilometer: (state: OptionsState, action: PayloadAction<number>): void => {
            state.vehicleCostsPerKilometer = action.payload;
        },
        setCurrency: (state: OptionsState, action: PayloadAction<string>): void => {
            state.currency = action.payload;
        },
    },
});

export const optionsActions = actions;
export const optionsReducer = reducer;
