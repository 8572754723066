import slice from 'lodash/fp/slice';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';

import { getLocale } from '../../configuration';
import { fetchData } from '../../configuration/setup/fetch';
import { getHereSettings } from '../../application/redux/data/dataSelectors';
import { Address, searchActions } from '../../application/redux/search/searchReducer';
import { searchPoiAddresses } from './searchPoiAddresses';
import { searchGeofenceAddresses } from './searchGeofenceAddresses';
import { RootState } from '../../configuration/setup/store';

const AUTO_SUGGEST_ENDPOINT = 'https://autosuggest.search.hereapi.com/v1/autosuggest';

const withPosition = (address: Address) => !isEmpty(address.position);

const mapType = (address: Address) => ({
    ...address,
    type: address.resultType,
});

export const processAddresses = flow(filter(withPosition), slice(0, 5), map(mapType));

export const fetchAddress = async (value: string, currentMapCenter: any, locale: string, apikey: string) => {
    const query = value ? `q=${encodeURIComponent(value)}` : '';
    const at = currentMapCenter ? `at=${currentMapCenter.lat},${currentMapCenter.lng}` : '';

    const url = `${AUTO_SUGGEST_ENDPOINT}?${at}&${query}&apiKey=${apikey}&lang=${locale}`;
    const response = await fetchData(url, undefined);
    return processAddresses(response.items);
};

export const fetchAddresses = (id: number, value: string) => async (dispatch: Function, getState: () => RootState) => {
    try {
        const apikey = getHereSettings(getState())?.apikey;
        if (isNil(apikey)) {
            return;
        }

        const locale = getLocale(getState());
        const currentMapCenter = {
            lat: 48.13642,
            lng: 11.57755,
        };

        const processedAddresses = await fetchAddress(value, currentMapCenter, locale, apikey);

        const poiAddresses = searchPoiAddresses(getState, value);
        const geofenceAddresses = searchGeofenceAddresses(getState, value);

        const allAddresses = processedAddresses.concat(poiAddresses).concat(geofenceAddresses);

        dispatch(searchActions.suggestedAddressesChanged({ id, addresses: allAddresses }));
    } catch (error) {
        dispatch(searchActions.fetchSuggestedAddressesFailed());
    }
};
