import cloneDeep from 'lodash/fp/cloneDeep';
import merge from 'lodash/fp/merge';
import { sendError } from '../lang/services';

const authorizeFetch = (token, fetch) => (url, options) =>
    fetch(
        url,
        merge(cloneDeep(options), {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
    );

export const fetchData = async (url, token, options) => {
    try {
        // const response = await authorizeFetch(token, window.fetch)(url, options);
        const response = token ? await authorizeFetch(token, window.fetch)(url, options) : await fetch(url, options);

        if (response.ok) {
            return response.json().catch(error => new Error(`${response.status} Invalid payload: ${error.message}`));
        }

        if (response.status === 401) {
            const error = new Error(`UNAUTHENTICATED when calling ${response.url}`);
            sendError(error);
            return Promise.reject(error);
        }

        return Promise.reject(new Error(`${response.status} Backend error: ${response.statusText}`));
    } catch (error) {
        const newError = new Error(`Error fetching data from backend ${url} with ${options} due to ${error}`);
        sendError(newError);
        return Promise.reject(newError);
    }
};
