import { HEREVehicleAttibuteNames, VehicleAttributes } from '../../../application/redux/options/optionsReducer';
import { debouncedGaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { LabeledNumberInput } from '../../common/displaycomponents/LabeledNumberInput';

export const AxleInputs = (props: AxleInputsProps) => {
    const { vehicleAxles, trailerAxles, onVehicleAttributesUpdate = () => {} } = props;

    const handleVehicleAxlesChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.VEHCILE_NUMBER_AXLES]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Vehicle Axles Input Changed',
            label: `Changed Vehicle Axles Input::${value}`,
        });
    };

    const handleTrailerAxlesChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.TRAILER_NUMBER_AXLES]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Trailer Axles Input Changed',
            label: `Changed Trailer Axles Input::${value}`,
        });
    };

    return (
        <div className={'display-flex align-items-end margin-bottom-10'}>
            <LabeledNumberInput
                className={'flex-1-1-0 padding-right-5'}
                labelId={'intl-msg:routeplanning.label.vehicleAxles'}
                value={vehicleAxles}
                integerOnly={true}
                unitIcon={'axle'}
                onChange={handleVehicleAxlesChange}
            />
            <LabeledNumberInput
                className={'flex-1-1-0 padding-left-5'}
                labelId={'intl-msg:routeplanning.label.trailerAxles'}
                value={trailerAxles}
                integerOnly={true}
                unitIcon={'axle'}
                onChange={handleTrailerAxlesChange}
            />
        </div>
    );
};

interface AxleInputsProps {
    vehicleAxles?: number;
    trailerAxles?: number;
    onVehicleAttributesUpdate?: (attributes: Partial<VehicleAttributes>) => void;
}
