import { VehicleAttributes } from '../../../application/redux/options/optionsReducer';

export enum Profile {
    TRUCK_AND_TRAILER_40T = 'Truck & Trailer 40t',
    TRUCK_11T = 'Truck 11t',
    TRUCK_7T5 = 'Truck 7.5t',
    TRANSPORTER_3T5 = 'Transporter 3.5t',
    CAR = 'Car',
}

export enum TollVehicleType {
    CAR = 2,
    TRUCK = 3,
}

export enum EmissionClass {
    NONE = '',
    EURO_I = '1',
    EURO_II = '2',
    EURO_III = '3',
    EURO_IV = '4',
    EURO_V = '5',
    EURO_VI = '6',
    EURO_EEV = '7',
    ELECTRIC_VEHICLE = '8',
}

export enum HazardClass {
    NONE = '',
    EXPLOSIVE = 'explosive',
    GAS = 'gas',
    FLAMMABLE = 'flammable',
    COMBUSTIBLE = 'combustible',
    ORGANIC = 'organic',
    POISON = 'poison',
    RADIOACTIVE = 'radioActive',
    CORROSIVE = 'corrosive',
    INHALATION_HAZARD = 'poisonousInhalation',
    HARMFUL_TO_WATER = 'harmfulToWater',
    MISCELLANEOUS = 'other',
}

export const truckAndTrailer40tAttributes: VehicleAttributes = {
    tollVehicleType: TollVehicleType.TRUCK,
    height: 4,
    length: 16.5,
    width: 2.55,
    limitedWeight: 40,
    weightPerAxle: 8.5,
    vehicleNumberAxles: 2,
    trailerNumberAxles: 3,
    emissionType: EmissionClass.EURO_VI,
};

const truck11tAttributes: VehicleAttributes = {
    tollVehicleType: TollVehicleType.TRUCK,
    height: 3.8,
    length: 10,
    width: 2.55,
    limitedWeight: 11,
    weightPerAxle: 5.5,
    vehicleNumberAxles: 2,
    trailerNumberAxles: 0,
    emissionType: EmissionClass.EURO_VI,
};

const truck7t5Attributes: VehicleAttributes = {
    tollVehicleType: TollVehicleType.TRUCK,
    height: 3.4,
    length: 7.2,
    width: 2.5,
    limitedWeight: 7.5,
    weightPerAxle: 3.75,
    vehicleNumberAxles: 2,
    trailerNumberAxles: 0,
    emissionType: EmissionClass.EURO_VI,
};

const transporter3t5Attributes: VehicleAttributes = {
    tollVehicleType: TollVehicleType.CAR,
    height: 2.55,
    length: 6.52,
    width: 1.94,
    limitedWeight: 3.5,
    weightPerAxle: 1.75,
    vehicleNumberAxles: 2,
    trailerNumberAxles: 0,
};

const carAttributes: VehicleAttributes = {
    tollVehicleType: TollVehicleType.CAR,
    height: 1.67,
    length: 4.41,
    width: 1.8,
    limitedWeight: 1.739,
    vehicleNumberAxles: 2,
    trailerNumberAxles: 0,
};

export const vehicleAttributesProfiles = {
    [Profile.TRUCK_AND_TRAILER_40T]: truckAndTrailer40tAttributes,
    [Profile.TRUCK_11T]: truck11tAttributes,
    [Profile.TRUCK_7T5]: truck7t5Attributes,
    [Profile.TRANSPORTER_3T5]: transporter3t5Attributes,
    [Profile.CAR]: carAttributes,
};
