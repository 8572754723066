import React, { useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import isFinite from 'lodash/fp/isFinite';
import isInteger from 'lodash/fp/isInteger';

const useParseLocaleNumber = (intl: IntlShape, fractionDigits: number) => {
    const thousandSeparator = intl.formatNumber(1111).replace(/1/g, '');
    const decimalSeparator = intl.formatNumber(1.1).replace(/1/g, '');

    return (localeNumberString: string) => {
        let normalizedString = thousandSeparator
            ? localeNumberString.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
            : localeNumberString;
        normalizedString = decimalSeparator
            ? normalizedString.replace(new RegExp(`\\${decimalSeparator}`), '.')
            : normalizedString;

        return Number(Number(normalizedString).toFixed(fractionDigits));
    };
};

export const LabeledNumberInput = (props: LabeledNumberInputProps) => {
    const { className, labelId, value = 0, integerOnly = false, onChange = () => {}, unit, unitIcon } = props;

    const intl = useIntl();
    const parseLocaleNumber = useParseLocaleNumber(intl, 2);

    const formattedValue = intl.formatNumber(value);

    const [displayedValue, setDisplayedValue] = useState(formattedValue);

    useEffect(() => {
        if (parseLocaleNumber(displayedValue) !== value) {
            setDisplayedValue(formattedValue);
        }
    }, [parseLocaleNumber, displayedValue, value, formattedValue]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = (val: number) => isFinite(val) && val >= 0 && (!integerOnly || isInteger(val));

        const newValueString = event.target.value;
        const newValue = parseLocaleNumber(newValueString);
        if (isValid(newValue)) {
            setDisplayedValue(newValueString);
            onChange(newValue);
        }
    };

    const handleInputBlur = () => {
        if (displayedValue !== formattedValue) {
            setDisplayedValue(formattedValue);
        }
    };

    return (
        <div className={className}>
            <label className={'control-label'}>
                <FormattedMessage id={labelId} />
            </label>
            <div className={'input-group'}>
                <input
                    className={'form-control'}
                    inputMode={'decimal'}
                    value={displayedValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
                {unit && (
                    <div data-testid={`unit-label=${unit}`} className={'input-group-addon'}>
                        {unit}
                    </div>
                )}
                {unitIcon && (
                    <div className={'input-group-addon text-color-gray padding-right-10'}>
                        <span className={`rioglyph rioglyph-${unitIcon} text-size-20`} aria-hidden={'true'} />
                    </div>
                )}
            </div>
        </div>
    );
};

interface LabeledNumberInputProps {
    className?: string;
    labelId: string;
    value?: number;
    integerOnly?: boolean;
    onChange?: (value?: number) => void;
    unit?: string;
    unitIcon?: string;
}
