/* eslint-disable camelcase */

export interface Coordinates {
    latitude: number;
    longitude: number;
    address?: string;
}

export enum GeofenceType {
    Circle = 'CIRCLE',
    Polygon = 'POLYGON',
}

interface MetaData {
    created_at: string;
    modified_at: string;
    allowed_actions: string;
}

export interface Geofence {
    id: string;
    name?: string;
    type: GeofenceType;
    category?: string;
    active: boolean;

    // Polygon Geofence specific
    points?: Coordinates[];

    // Cirlce Geofence specific
    center?: Coordinates;
    radius?: number;

    // Unused data
    metadata?: MetaData;
    accountId?: string;
}

export const mapGeofence = (geofence: Geofence): Geofence => {
    const cleanedGeofence = { ...geofence };
    delete cleanedGeofence.metadata;
    delete cleanedGeofence.accountId;
    return cleanedGeofence;
};
