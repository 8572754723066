import Fuse from 'fuse.js';
import maxBy from 'lodash/fp/maxBy';
import minBy from 'lodash/fp/minBy';

import { Address } from '../../application/redux/search/searchReducer';
import { RootState } from '../../configuration/setup/store';
import { getGeofences } from '../../application/redux/data/dataSelectors';
import { Coordinates, Geofence } from './mappers/mapGeofence';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';

const getLat = (item: Coordinates): number => item.latitude;
const getLng = (item: Coordinates): number => item.longitude;

const getPolygonCenter = (geofence: Geofence): Coordinates | undefined => {
    const points = geofence.points;

    if (!points) {
        return;
    }

    const maxLat = maxBy(getLat)(points)?.latitude ?? 1;
    const maxLng = maxBy(getLng)(points)?.longitude ?? 1;

    const minLat = minBy(getLat)(points)?.latitude ?? 1;
    const minLng = minBy(getLng)(points)?.longitude ?? 1;

    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;

    const latitude = maxLat - latDiff / 2;
    const longitude = maxLng - lngDiff / 2;

    return {
        latitude,
        longitude,
    };
};

const mapAddress = (geofence: Geofence): Address => {
    const position = geofence.center || getPolygonCenter(geofence);

    return {
        title: `${geofence.name ?? ''} ${geofence.center?.address ?? ''}`.trim(),
        type: geofence.type.toLowerCase(),
        resultType: geofence.type.toLowerCase(),
        position: { lat: position!.latitude, lng: position!.longitude },
    };
};

const withPosition = (geofence: Geofence) => geofence.center !== undefined || geofence.points !== undefined;

const processGeofences = flow(filter(withPosition), map(mapAddress));

export const searchGeofenceAddresses = (getState: () => RootState, value: string) => {
    const geofences = getGeofences(getState());
    const options = {
        keys: ['name', 'address'],
        threshold: 0.2,
        distance: 30,
    };
    const fuse = new Fuse(geofences, options);
    const results = fuse.search(value);
    return processGeofences(results.map(result => result.item));
};
