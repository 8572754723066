import { fetchData } from '../../configuration/setup/fetch';
import { dataActions } from '../../application/redux/data/dataReducer';

import { config } from '../../config';
import { getAccessToken } from '../../configuration';
import { AppDispatch, RootState } from '../../configuration/setup/store';

export const fetchGeoBooked = async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const bookedUrl = `${config.backend.ROUTEPLANNING_SERVICE}/booked`;
        const bookedResponse = await fetchData(bookedUrl, getAccessToken(getState()));
        dispatch(dataActions.geoBookedChanged(bookedResponse?.booked));
    } catch (error: any) {
        dispatch(dataActions.fetchGeoBookedFailed(error));
    }
};
