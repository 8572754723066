import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';

import { Profile } from './VehicleAttributePresets';
import { optionsActions, VehicleAttributes } from '../../../application/redux/options/optionsReducer';
import { AppDispatch, RootState } from '../../../configuration/setup/store';
import {
    getHasCustomAttributes,
    getSelectedProfile,
    getVehicleAttributes,
} from '../../../application/redux/options/optionsSelectors';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { VehicleTypeSelect } from './VehicleTypeSelect';
import { VehicleDimensionsInputs } from './VehicleDimensionInputs';
import { WeightInputs } from './WeightInputs';
import { AxleInputs } from './AxleInputs';
import { EmissionAndHazardSelects } from './EmissionAndHazardSelects';
import { routesActions } from '../../../application/redux/routes/routesReducer';

export const VehicleOptions = (props: VehicleOptionsProps) => {
    const { selectedProfile, hasCustomAttributes, vehicleAttributes, onProfileChange, onVehicleAttributesUpdate } =
        props;

    const {
        length,
        height,
        width,
        limitedWeight,
        weightPerAxle,
        vehicleNumberAxles,
        trailerNumberAxles,
        emissionType,
        shippedHazardousGoods,
    } = vehicleAttributes;

    const handleExpanderPanelToggle = (value: boolean) => {
        gaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Vehicle Options Expander Toggled',
            label: `Toggled Vehicle Options Expander::${value ? 'Open' : 'Closed'}`,
        });
    };

    return (
        <ExpanderPanel
            className={'VehicleOptions margin-bottom-0'}
            title={<FormattedMessage id={'intl-msg:routeplanning.label.vehicleOptions'} />}
            iconLeft
            bsStyle={'separator'}
            onToggle={handleExpanderPanelToggle}
        >
            <VehicleTypeSelect
                profile={selectedProfile}
                onProfileChange={onProfileChange}
                hasCustomAttributes={hasCustomAttributes}
            />
            <VehicleDimensionsInputs
                totalLength={length}
                height={height}
                width={width}
                onVehicleAttributesUpdate={onVehicleAttributesUpdate}
            />
            <WeightInputs
                maxWeight={limitedWeight}
                maxAxleLoad={weightPerAxle}
                onVehicleAttributesUpdate={onVehicleAttributesUpdate}
            />
            <AxleInputs
                vehicleAxles={vehicleNumberAxles}
                trailerAxles={trailerNumberAxles}
                onVehicleAttributesUpdate={onVehicleAttributesUpdate}
            />
            <EmissionAndHazardSelects
                emissionClass={emissionType}
                hazardClass={shippedHazardousGoods}
                onVehicleAttributesUpdate={onVehicleAttributesUpdate}
            />
        </ExpanderPanel>
    );
};

type VehicleOptionsProps = ConnectedProps<typeof connector>;

export const mapStateToProps = (state: RootState) => ({
    selectedProfile: getSelectedProfile(state),
    hasCustomAttributes: getHasCustomAttributes(state),
    vehicleAttributes: getVehicleAttributes(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onProfileChange: (profile: Profile) => {
        dispatch(optionsActions.selectedProfileChanged(profile));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
    onVehicleAttributesUpdate: (attributes: Partial<VehicleAttributes>) => {
        dispatch(optionsActions.vehicleAttributesUpdated(attributes));
        dispatch(routesActions.suggestedRoutesRemoved());
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const VehicleOptionsContainer = connector(VehicleOptions);
