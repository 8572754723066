import './polyfills';
import { Provider } from 'react-redux';

import { handleLoginRedirect, main } from './configuration';
import { store } from './configuration/setup/store';

import { config } from './config';
import { AppContainer } from './features/app/App';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import './configuration/setup/datadog';
import { createRoot } from 'react-dom/client';

const renderApplication = () => {
    const root = document.getElementById('root');

    // Break the pageLoader as soon as possible without waiting for React to render the Application
    if (root) {
        root.innerHTML = '<div></div>';
    }

    createRoot(root as HTMLElement).render(
        <ErrorBoundary>
            <Provider store={store}>
                <AppContainer />
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
