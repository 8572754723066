import sortBy from 'lodash/fp/sortBy';
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';

import { Coordinates, Waypoint } from '../../../application/redux/search/searchReducer';
import { WaypointWithCoordinates } from '../fetchWaypointCoordinates';

export interface Route {
    waypoints: WaypointWithCoordinates[];
    routeCoordinates: Coordinates[];
    summary: {
        distance: number;
        travelTime: number;
        departure: string;
        arrival: string;
    };
    costs: {
        totalCost: number;
        vehicleCost: number;
        tollCost: number;
        currency: string;
    };
}

interface HereLink {
    shape: number[];
}

interface HereLeg {
    link: HereLink[];
}

interface HereRoute {
    waypoint: [
        {
            mappedPosition: {
                latitude: number;
                longitude: number;
            };
        }
    ];
    leg: HereLeg[];
}

const extractCoordinatesFromLegs = flow(
    flatMap((leg: HereLeg) => leg.link),
    flatMap((link: HereLink) => {
        const numberOfCoordinatesExcludingLastOne = link.shape.length / 2 - 1;
        const linkCoordinates: Coordinates[] = [];
        for (let i = 0; i < numberOfCoordinatesExcludingLastOne; i++) {
            linkCoordinates.push({ lat: link.shape[2 * i], lng: link.shape[2 * i + 1] });
        }
        return linkCoordinates;
    })
);

const extractLastCoordinatesFromLastLeg = (route: HereRoute): Coordinates => {
    const lastLeg = route.leg[route.leg.length - 1];
    const lastLink = lastLeg.link[lastLeg.link.length - 1];
    const lastLinkLength = lastLink.shape.length;

    return { lat: lastLink.shape[lastLinkLength - 2], lng: lastLink.shape[lastLinkLength - 1] };
};

const extractCoordinatesFromRoute = (route: HereRoute): Coordinates[] => {
    const routeCoordinates = extractCoordinatesFromLegs(route.leg);
    routeCoordinates.push(extractLastCoordinatesFromLastLeg(route));

    return routeCoordinates;
};

const extractAndFormatWaypoints = (route: HereRoute, waypointsFromSearch: Waypoint[]): WaypointWithCoordinates[] =>
    route.waypoint.map((waypoint, index) => ({
        id: waypointsFromSearch[index].id,
        address: waypointsFromSearch[index].address,
        coordinates: { lat: waypoint.mappedPosition.latitude, lng: waypoint.mappedPosition.longitude },
    }));

const sortByTravelTime = sortBy<Route>('summary.travelTime');

const mapRoute = (route: any, waypointsFromSearch: Waypoint[]): Route => ({
    waypoints: extractAndFormatWaypoints(route, waypointsFromSearch),
    routeCoordinates: extractCoordinatesFromRoute(route),
    summary: {
        distance: route.summary.distance,
        travelTime: route.summary.trafficTime,
        departure: route.summary.departure,
        arrival: route.summary.arrival,
    },
    costs: {
        totalCost: parseFloat(route.cost.totalCost),
        vehicleCost: parseFloat(route.cost.details.vehicleCost),
        tollCost: parseFloat(route.cost.details.tollCost),
        currency: route.cost.currency,
    },
});

export const mapRoutes = (routingResult: { response: { route: any } }, waypointsFromSearch: Waypoint[]): Route[] =>
    sortByTravelTime(routingResult.response.route.map((route: any) => mapRoute(route, waypointsFromSearch)));
