import { FormattedMessage } from 'react-intl';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { ReactElement } from 'react';

const checkboxText = (intlId: string): ReactElement => (
    <span>
        <FormattedMessage id={intlId} />
    </span>
);

export const TourOption = ({ intlId, checked, onToggle, tooltipContent }: TourOptionProps) => {
    const tooltip = (
        <Tooltip id='tooltip-ignoreRestrictionsAroundWaypoints' width={250}>
            <div className='text-left'>{tooltipContent}</div>
        </Tooltip>
    );

    if (tooltipContent !== undefined) {
        return (
            <Checkbox checked={checked} onClick={() => onToggle(!checked)}>
                <OverlayTrigger placement='bottom' overlay={tooltip}>
                    {checkboxText(intlId)}
                </OverlayTrigger>
            </Checkbox>
        );
    } else {
        return (
            <Checkbox checked={checked} onClick={() => onToggle(!checked)}>
                {checkboxText(intlId)}
            </Checkbox>
        );
    }
};

interface TourOptionProps {
    intlId: string;
    checked: boolean;
    onToggle: (checked: boolean) => void;
    tooltipContent?: string;
}
