import debounce from 'lodash/fp/debounce';

export const rawDataLayerPush = val => {
    if (window.dataLayer) {
        window.dataLayer.push(val);
    }
};
export const gaPush = ({ action, label, category }) =>
    rawDataLayerPush({
        event: 'ga_event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
    });

export const debouncedGaPush = debounce(1000, ({ category, action, label }) => {
    gaPush({
        category,
        action,
        label,
    });
});

// categories as defined for all services on:
// https://collaboration.msi.audi.com/confluence/display/MAN/Overarching+Topics
export const TRACKING_CATEGORIES = {
    WAYPOINT_EDITOR: 'routePlanning, waypointEditor',
    TOUR_OPTIONS_EDITOR: 'routePlanning, tourOptionsEditor',
    ROUTE_CHOOSER: 'routePlanning, routeChooser',
    UPSELLING: 'routePlanning, upselling',
    COST_DETAILS: 'routePlanning, tourCostDetails'
};

export const TRACKING_ACTIONS = {};

export const TRACKING_LABELS = {};

export const executeAndTrack = (func, val) => (...args) => {
    func.apply(null, args);
    rawDataLayerPush(val);
};

export const trackingAttributes = (category, action, label, value) => {
    const attributes = {
        'data-track-trigger': 'click',
        'data-track-category': category,
        'data-track-action': action,
    };

    if (label) {
        attributes['data-track-label'] = label;
    }

    if (value) {
        attributes['data-track-value'] = value;
    }

    return attributes;
};
