import { fetchData } from '../../configuration/setup/fetch';
import { config } from '../../config';
import { getAccessToken } from '../../configuration';
import { dataActions } from '../../application/redux/data/dataReducer';
import { mapGeofence, Geofence } from './mappers/mapGeofence';

export const fetchGeofences = async (dispatch: Function, getState: Function) => {
    try {
        const response = await fetchData(config.backend.GEOFENCE_SERVICE, getAccessToken(getState()));
        const items = response.items || [];
        const geofences = items.map((geofence: Geofence) => mapGeofence(geofence));

        dispatch(dataActions.fetchGeofencesSuccess(geofences));
    } catch (error) {
        dispatch(dataActions.fetchGeofencesFailed());
    }
};
