import { OptionsState, VehicleAttributes } from './optionsReducer';
import { Profile } from '../../../features/options/vehicle/VehicleAttributePresets';
import { RootState } from '../../../configuration/setup/store';

const getBase = (state: RootState): OptionsState => state.options;

export const getDepartureTime = (state: RootState): string => getBase(state).departureTime;
export const getDepartureDate = (state: RootState): Date => getBase(state).departureDate;

export const getConsiderTraffic = (state: RootState): boolean => getBase(state).considerTraffic;
export const getAvoidMotorway = (state: RootState): boolean => getBase(state).avoidMotorway;
export const getAvoidToll = (state: RootState): boolean => getBase(state).avoidToll;
export const getIgnoreRestrictionsAroundWaypoints = (state: RootState): boolean =>
    getBase(state).ignoreRestrictionsAroundWaypoints;

export const getSelectedProfile = (state: RootState): Profile => getBase(state).selectedProfile;
export const getHasCustomAttributes = (state: RootState): boolean => getBase(state).hasCustomAttributes;
export const getVehicleAttributes = (state: RootState): VehicleAttributes => getBase(state).vehicleAttributes;

export const getCalculateAlternatives = (state: RootState): boolean => getBase(state).calculateAlternatives;

export const getVehicleCostsPerKilometer = (state: RootState): number => getBase(state).vehicleCostsPerKilometer;
export const getCurrency = (state: RootState): string => getBase(state).currency;
