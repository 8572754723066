import React, { FunctionComponent } from 'react';
import noop from 'lodash/fp/noop';
import { batch, connect, ConnectedProps } from 'react-redux';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { searchActions, Waypoint } from '../../application/redux/search/searchReducer';
import { getOrderedWaypoints } from '../../application/redux/search/searchSelectors';
import { AppDispatch, RootState } from '../../configuration/setup/store';
import { routesActions } from '../../application/redux/routes/routesReducer';
import { clearRoute } from '../livemonitorCommunication/propagateRoutesToParent';
import { WaypointListItemContainer } from './WaypointListItem';
import { WaypointsOptionsContainer } from './WaypointsOptions';

type OnSortEndProps = {
    oldIndex: number;
    newIndex: number;
};

type SortableContainerProps = {
    items: Waypoint[];
};

const SortableWaypointContainer = SortableContainer<SortableContainerProps>(({ items }: SortableContainerProps) => {
    const allowRemove = items.length > 2;
    const totalWaypoints = items.length - 1;
    return (
        <div className={'bg-lightest'}>
            {items.map((waypoint: Waypoint, index: number) => (
                <WaypointListItemContainer
                    key={`item-${waypoint.id}`}
                    orderIndex={index}
                    index={index}
                    value={waypoint}
                    allowRemove={allowRemove}
                    totalWaypoints={totalWaypoints}
                />
            ))}
        </div>
    );
});

export const SearchEditor: FunctionComponent<SearchEditorProps> = React.memo(props => {
    const { waypoints, waypointOrderChanged } = props;

    const onSortEnd = ({ oldIndex, newIndex }: OnSortEndProps) => {
        const newWaypoints = arrayMove(waypoints, oldIndex, newIndex);
        waypointOrderChanged(newWaypoints);
    };

    return (
        <div className={'RoutePlannerSearch'} data-testid={'RoutePlannerSearch'}>
            <SortableWaypointContainer
                onSortEnd={onSortEnd}
                items={waypoints}
                useDragHandle={true}
                lockAxis={'y'}
                helperClass={'z-index-max'}
            />
            <WaypointsOptionsContainer />
        </div>
    );
});

SearchEditor.defaultProps = {
    waypoints: [],
    waypointOrderChanged: () => noop,
};

type SearchEditorProps = ConnectedProps<typeof connector>;

export const mapStateToProps = (state: RootState) => ({
    waypoints: getOrderedWaypoints(state),
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    waypointOrderChanged: (waypoints: Waypoint[]) => {
        batch(() => {
            dispatch(searchActions.waypointsOrderChanged(waypoints));
            dispatch(routesActions.suggestedRoutesRemoved());
            clearRoute();
        });
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const SearchEditorContainer = connector(SearchEditor);
export default SearchEditorContainer;
