import { HEREVehicleAttibuteNames, VEHCILE_ATTRIBUTES_UNITS, VehicleAttributes } from '../../../application/redux/options/optionsReducer';
import { debouncedGaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { LabeledNumberInput } from '../../common/displaycomponents/LabeledNumberInput';

export const WeightInputs = (props: WeightInputsProps) => {
    const { maxWeight, maxAxleLoad, onVehicleAttributesUpdate = () => {} } = props;

    const handleMaxWeightChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.LIMITED_WEIGHT]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Max Weight Input Changed',
            label: `Changed Max Weight Input::${value}`,
        });
    };

    const handleMaxAxleLoadChange = (value?: number) => {
        onVehicleAttributesUpdate({ [HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]: value });
        debouncedGaPush({
            category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
            action: 'Max Axle Load Input Changed',
            label: `Changed Max Axle Load Input::${value}`,
        });
    };

    return (
        <div className={'display-flex align-items-end margin-bottom-10'}>
            <LabeledNumberInput
                className={'flex-1-1-0 padding-right-5'}
                labelId={'intl-msg:routeplanning.label.maxWeight'}
                value={maxWeight}
                unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.LIMITED_WEIGHT]}
                onChange={handleMaxWeightChange}
            />
            <LabeledNumberInput
                className={'flex-1-1-0 padding-left-5'}
                labelId={'intl-msg:routeplanning.label.maxAxleLoad'}
                value={maxAxleLoad}
                unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]}
                onChange={handleMaxAxleLoadChange}
            />
        </div>
    );
};

interface WeightInputsProps {
    maxWeight?: number;
    maxAxleLoad?: number;
    onVehicleAttributesUpdate?: (attributes: Partial<VehicleAttributes>) => void;
}
